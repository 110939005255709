<template>
	<div>
		<a-table
			style="font-size: 12px"
			:columns="feeColumns"
			size="small"
			:rowKey="(record, index) => index"
			:dataSource="feeEvents"
			:pagination="false"
			bordered
		>
			<b slot="title">
				{{ $t(`table.finance.lenging.tabs.1`) }}
			</b>
			<span slot="name" slot-scope="obj">
				{{ $t(`table.finance.lenging.typeMap.${obj.type.value}`) }}
			</span>
			<span slot="receipt" slot-scope="obj">
				<template v-if="obj.certificate">
					<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(obj.certificate.split('?')[0].split('.').pop().toLowerCase())">
						<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, obj.certificate)" alt="" width="100" height="50" />
					</viewer>
					<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.certificate)" target="_blank">{{
						obj.certificate.split('/')[obj.certificate.split('/').length - 1]
					}}</a>
				</template>
				<template v-if="obj.certificates">
					<span v-for="(img, index) in obj.certificates" :key="index">
						<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(img.split('?')[0].split('.').pop().toLowerCase())">
							<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, img)" alt="" width="100" height="50" />
						</viewer>
						<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, img)" target="_blank">{{
							img.split('/')[img.split('/').length - 1]
						}}</a>
						<br />
					</span>
				</template>
			</span>
			<span slot="amount" slot-scope="obj">
				<!-- <a @click="SeeModal(obj)" v-if="obj.type.value == 8">{{ obj.amount }}</a> -->
				<template v-if="obj.type.value == 8">
					<p v-for="(detail, index) in obj.details" :key="index"> {{ detail.feeName }} : {{ detail.amount }} </p>
				</template>
				<span v-if="obj.type.value != 8">{{ obj.amount }}</span>
			</span>
			<span slot="action" slot-scope="obj">
				<a @click="SeePenaltyModal(obj)">{{ obj.type.value == 1 || obj.type.value == 0 ? 'Recalculate Penalty' : 'Amend Period' }}</a>
				<a-divider type="vertical" />
				<a @click="deletePenalty(obj)">Delete</a>
			</span>
		</a-table>
		<a-table
			style="font-size: 12px; margin-top: 20px"
			:columns="insuranceColumns"
			size="small"
			:rowKey="(record, index) => index"
			:dataSource="insuranceFeeEvents"
			:pagination="false"
			bordered
		>
			<b slot="title">{{ $t(`table.finance.lenging.penaltyRecord`) }}</b>
			<span slot="name" slot-scope="obj">
				{{ $t(`table.finance.lenging.typeMap.${obj.type.value}`) }}
			</span>
			<span slot="amount" slot-scope="obj">
				<template v-if="obj.type.value == 8">
					<p v-for="(detail, index) in obj.certificates" :key="index"> {{ detail.feeName }} : {{ detail.amount }} </p>
				</template>
				<span v-if="obj.type.value != 8">{{ obj.amount }}</span>
			</span>
		</a-table>
		<a-table
			style="font-size: 12px; margin-top: 20px"
			:columns="emergencyColumns"
			size="small"
			:rowKey="(record, index) => index"
			:dataSource="emergencyFeeEvents"
			:pagination="false"
			bordered
		>
			<b slot="title">{{ $t(`table.finance.lenging.penalRec`) }}</b>
			<span slot="name" slot-scope="obj">
				{{ $t(`table.finance.lenging.typeMap.${obj.type.value}`) }}
			</span>
			<span slot="amount" slot-scope="obj">
				<template v-if="obj.type.value == 8">
					<p v-for="(detail, index) in obj.certificates" :key="index"> {{ detail.feeName }} : {{ detail.amount }} </p>
				</template>
				<span v-if="obj.type.value != 8">{{ obj.amount }}</span>
			</span>
		</a-table>

		<a-modal
			v-drag-modal
			v-model="penaltyModalShow"
			width="600px"
			:title="penaltyType === 0 || penaltyType === 1 ? 'Recalculate Penalty' : 'Amend Period'"
			okText="Confirm"
			@ok="handModalOk"
		>
			<div v-if="penaltyType === 0 || penaltyType === 1">
				<p>Please select the time that the penalty should be generated, then it will be recalculated automatically:</p>
				<p><a-date-picker v-model="penaltyTime" format="YYYY-MM-DD HH:mm:ss" show-time /></p>
			</div>
			<div v-else>
				<p>Please choose the period you want to input:</p>
				<p>
					<a-select v-model="checkInstallments" style="width: 200px">
						<a-select-option :value="item" v-for="item in installmentsOptions" :key="item">{{ item }}</a-select-option>
					</a-select>
				</p>
			</div>
		</a-modal>
	</div>
</template>
<script>
import { apiRegeneratePenalty, apiModifyFee, apiDeleteFee } from '@/api/loan'
import moment from 'moment'
export default {
	props: ['feeEvents', 'insuranceFeeEvents', 'emergencyFeeEvents', 'installmentsNumbers'],
	data() {
		return {
			penaltyModalShow: false,
			penaltyTime: '',
			penaltyId: '',
			checkInstallments: null,
			penaltyType: null
		}
	},
	computed: {
		installmentsOptions() {
			let arr = []
			for (let index = 1; index < this.installmentsNumbers + 1; index++) {
				arr.push(index)
			}
			return arr
		},
		feeColumns() {
			return [
				{
					// 名称
					title: this.$t('table.finance.lenging.cloums.name'),
					align: 'center',
					scopedSlots: { customRender: 'name' }
				},
				{
					// 提交时间 arrivalTime
					title: this.$t('table.finance.cloum.arrivalTime'),
					align: 'center',
					dataIndex: 'createTime'
				},
				{
					// 费用金额
					title: this.$t('table.finance.lenging.cloums.amount'),
					align: 'center',
					// dataIndex: "amount",
					scopedSlots: { customRender: 'amount' }
				},
				{
					// 费用金额
					title: this.$t('table.finance.lenging.cloums.receipt'),
					align: 'center',
					scopedSlots: { customRender: 'receipt' }
				},
				{
					// 操作员
					title: this.$t('table.finance.lenging.cloums.actioner'),
					align: 'center',
					dataIndex: 'createUserName'
				},
				{
					// 录入结果
					title: this.$t('table.finance.lenging.cloums.result'),
					align: 'center',
					dataIndex: 'success',
					customRender: (v, o) => {
						return v ? this.$t('table.finance.lenging.resultMap.1') : this.$t('table.finance.lenging.resultMap.0')
					}
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			]
		},
		insuranceColumns() {
			return [
				{
					// 名称
					title: this.$t('table.finance.lenging.cloums.name'),
					align: 'center',
					scopedSlots: { customRender: 'name' }
				},
				{
					// 提交时间
					title: this.$t('table.finance.lenging.cloums.submitTime'),
					align: 'center',
					dataIndex: 'createTime'
				},
				{
					// 费用金额
					title: this.$t('table.finance.lenging.cloums.amount'),
					align: 'center',
					// dataIndex: "amount",
					scopedSlots: { customRender: 'amount' }
				},
				{
					// 录入结果
					title: this.$t('table.finance.lenging.cloums.result'),
					align: 'center',
					dataIndex: 'success',
					customRender: (v, o) => {
						return v ? this.$t('table.finance.lenging.resultMap.1') : this.$t('table.finance.lenging.resultMap.0')
					}
				}
			]
		},
		emergencyColumns() {
			return [
				{
					// 名称
					title: this.$t('table.finance.lenging.cloums.name'),
					align: 'center',
					scopedSlots: { customRender: 'name' }
				},
				{
					// 提交时间
					title: this.$t('table.finance.lenging.cloums.submitTime'),
					align: 'center',
					dataIndex: 'createTime'
				},
				{
					// 费用金额
					title: this.$t('table.finance.lenging.cloums.amount'),
					align: 'center',
					// dataIndex: "amount",
					scopedSlots: { customRender: 'amount' }
				},
				{
					// 录入结果
					title: this.$t('table.finance.lenging.cloums.result'),
					align: 'center',
					dataIndex: 'success',
					customRender: (v, o) => {
						return v ? this.$t('table.finance.lenging.resultMap.1') : this.$t('table.finance.lenging.resultMap.0')
					}
				}
			]
		}
	},
	methods: {
		moment: moment,
		SeePenaltyModal(obj) {
			this.penaltyModalShow = true
			this.penaltyType = obj.type.value
			this.penaltyId = obj.id
		},
		deletePenalty(obj) {
			this.$confirm({
				content: 'Are you sure to delete the charge?',
				onOk: () => {
					apiDeleteFee({
						id: obj.id, //罚息的记录id
						loanId: this.$route.query.id, //案件id
						feeType: obj.type.value
					}).then((res) => {
						this.$message.success('success')
						location.reload()
					})
				},
				onCancel() {}
			})
		},
		handModalOk() {
			if (this.penaltyType === 0 || this.penaltyType === 1) {
				if (!this.penaltyTime) {
					this.$message.error('Penalty Time is empty!')
					return false
				}
			} else {
				if (!this.checkInstallments) {
					this.$message.error('Period is empty!')
					return false
				}
			}
			this.$confirm({
				content: this.$t('table.applyInfo.msgMap.msg1'),
				onOk: () => {
					if (this.penaltyType === 0 || this.penaltyType === 1) {
						apiRegeneratePenalty({
							id: this.penaltyId, //罚息的记录id
							loanId: this.$route.query.id, //案件id
							penaltyTime: moment(this.penaltyTime).format('YYYY-MM-DD HH:mm:ss')
						}).then((res) => {
							this.$message.success('success')
							this.penaltyModalShow = false
							location.reload()
						})
					} else {
						apiModifyFee({
							id: this.penaltyId, //罚息的记录id
							loanId: this.$route.query.id, //案件id
							feeType: this.penaltyType,
							installments: this.checkInstallments
						}).then((res) => {
							this.$message.success('success')
							this.penaltyModalShow = false
							location.reload()
						})
					}
				},
				onCancel() {}
			})
		}
	}
}
</script>
