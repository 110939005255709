var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.pageState.fromPath.indexOf('collections') != -1)?[(_vm.lengingInfo.loanStatus < 1008)?_c('settlement-model',{staticStyle:{"margin-right":"10px"},attrs:{"title":"Early Settlement Form","type":0,"onlyDownload":true,"lengingInfo":_vm.lengingInfo}}):_vm._e()]:_vm._e(),(_vm.pageState.fromPath.indexOf('collections') === -1)?[(_vm.repayDataSource && _vm.lengingInfo.loanStatus < 1008)?_c('settlement-model',{ref:"settlementPaymentIds",staticStyle:{"margin-right":"10px"},attrs:{"title":"Early Settlement","type":0,"lengingInfo":_vm.lengingInfo}}):_vm._e(),(_vm.repayDataSource)?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.earlSettlUndo(0)}}},[_vm._v("Early Settlement Undo")]):_vm._e(),_c('br'),(_vm.repayDataSource && _vm.lengingInfo.loanStatus < 1008)?_c('settlement-model',{staticStyle:{"margin-top":"10px","margin-right":"10px"},attrs:{"title":"Auction Settlement","btnColor":"blueviolet","type":1,"lengingInfo":_vm.lengingInfo}}):_vm._e(),(_vm.repayDataSource)?_c('a-button',{staticClass:"purple-btn",staticStyle:{"margin-top":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.earlSettlUndo(1)}}},[_vm._v("Auction Settlement Undo")]):_vm._e()]:_vm._e(),_c('a-table',{staticStyle:{"font-size":"12px","margin-top":"10px"},attrs:{"columns":_vm.repaymentColumns,"size":"small","rowKey":(record, index) => index,"dataSource":_vm.repayDataSource,"pagination":false,"scroll":{
			x: true
		},"row-selection":_vm.repayRowSelection,"bordered":""},scopedSlots:_vm._u([{key:"otherFeePaid",fn:function(obj){return _c('span',{},[_vm._v(" "+_vm._s(obj.otherFeeExtraPaid)+"/"),_c('a',{on:{"click":function($event){return _vm.SeeModal(obj)}}},[_vm._v(_vm._s(obj.otherFeeDetails))])])}}])},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(`${_vm.$t(`table.finance.lenging.tabs.2`)} Of ${_vm.$t(`table.applyInfo.financialLabel.loanMap.${_vm.lengingInfo.loanType}`)}`)+" "),(_vm.lengingInfo.loanStatus < 1008 && _vm.pageState.fromPath.indexOf('collections') === -1)?_c('span',[[_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.repayUndo('repay')}}},[_vm._v(" Undo ")])]],2):_vm._e()])]),_c('a-table',{staticStyle:{"font-size":"12px","margin-top":"20px"},attrs:{"columns":_vm.insuranceColumns,"size":"small","rowKey":(record, index) => index,"dataSource":_vm.insuRepayDataSource,"pagination":false,"scroll":{
			x: true
		},"row-selection":_vm.insuRepayRowSelection,"bordered":""},scopedSlots:_vm._u([{key:"otherFeePaid",fn:function(obj){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.SeeModal(obj)}}},[_vm._v(_vm._s(obj.otherFeeDetails))])])}}])},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t(`table.finance.lenging.insRepayDet`))+" "),(_vm.lengingInfo.loanStatus < 1008 && _vm.pageState.fromPath.indexOf('collections') === -1)?_c('span',[_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.repayUndo('insuRepay')}}},[_vm._v(" Undo ")])],1):_vm._e()])]),_c('a-table',{staticStyle:{"font-size":"12px","margin-top":"20px"},attrs:{"columns":_vm.emergencyColumns,"size":"small","rowKey":(record, index) => index,"dataSource":_vm.emergencyRepayDataSource,"pagination":false,"scroll":{
			x: true
		},"row-selection":_vm.emergencyRepayRowSelection,"bordered":""},scopedSlots:_vm._u([{key:"otherFeePaid",fn:function(obj){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.SeeModal(obj)}}},[_vm._v(_vm._s(obj.otherFeeDetails))])])}}])},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t(`table.finance.lenging.repDet`))+" "),(_vm.lengingInfo.loanStatus < 1008 && _vm.pageState.fromPath.indexOf('collections') === -1)?_c('span',[_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.repayUndo('emergencyRepay')}}},[_vm._v(" Undo ")])],1):_vm._e()])]),_c('div',{staticClass:"remark"},[_vm._v("tips: "+_vm._s(_vm.$t('table.finance.cloumRepayment.tips2')))]),_c('a-modal',{directives:[{name:"drag-modal",rawName:"v-drag-modal"}],attrs:{"footer":false,"width":"600px","title":_vm.$t(`table.finance.customerFee.cloum.otherFee`)},model:{value:(_vm.FeeModal.show),callback:function ($$v) {_vm.$set(_vm.FeeModal, "show", $$v)},expression:"FeeModal.show"}},[_c('a-table',{staticStyle:{"font-size":"12px"},attrs:{"columns":_vm.FeeModal.columns,"size":"small","rowKey":(record, index) => index,"dataSource":_vm.FeeModal.dataSource,"pagination":false,"bordered":""}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }