<template>
	<div>
		<!-- 催收专属 -->
		<template v-if="pageState.fromPath.indexOf('collections') != -1">
			<settlement-model
				v-if="lengingInfo.loanStatus < 1008"
				title="Early Settlement Form"
				:type="0"
				:onlyDownload="true"
				:lengingInfo="lengingInfo"
				style="margin-right: 10px"
			></settlement-model>
		</template>
		<!-- 财务专属 -->
		<template v-if="pageState.fromPath.indexOf('collections') === -1">
			<settlement-model
				ref="settlementPaymentIds"
				v-if="repayDataSource && lengingInfo.loanStatus < 1008"
				title="Early Settlement"
				:type="0"
				:lengingInfo="lengingInfo"
				style="margin-right: 10px"
			></settlement-model>
			<a-button v-if="repayDataSource" type="primary" @click="earlSettlUndo(0)">Early Settlement Undo</a-button>
			<br />
			<settlement-model
				v-if="repayDataSource && lengingInfo.loanStatus < 1008"
				title="Auction Settlement"
				btnColor="blueviolet"
				:type="1"
				:lengingInfo="lengingInfo"
				style="margin-top: 10px; margin-right: 10px"
			></settlement-model>
			<a-button v-if="repayDataSource" type="primary" class="purple-btn" @click="earlSettlUndo(1)" style="margin-top: 10px"
				>Auction Settlement Undo</a-button
			>
		</template>
		<a-table
			style="font-size: 12px; margin-top: 10px"
			:columns="repaymentColumns"
			size="small"
			:rowKey="(record, index) => index"
			:dataSource="repayDataSource"
			:pagination="false"
			:scroll="{
				x: true
			}"
			:row-selection="repayRowSelection"
			bordered
		>
			<span slot="title">
				{{ `${$t(`table.finance.lenging.tabs.2`)} Of ${$t(`table.applyInfo.financialLabel.loanMap.${lengingInfo.loanType}`)}` }}
				<span v-if="lengingInfo.loanStatus < 1008 && pageState.fromPath.indexOf('collections') === -1">
					<!-- <template v-if="repayRowSelection === null">
						<a-button type="primary" @click="selectRow('repay')" style="margin-left: 10px">Undo</a-button>
					</template> -->
					<template>
						<a-button type="primary" @click="repayUndo('repay')" style="margin-left: 10px"> Undo </a-button>
						<!-- <a-button @click="repayRowSelection = null" style="margin-left: 10px"> Cancel </a-button> -->
					</template>
				</span>
			</span>
			<span slot="otherFeePaid" slot-scope="obj">
				{{ obj.otherFeeExtraPaid }}/<a @click="SeeModal(obj)">{{ obj.otherFeeDetails }}</a>
			</span>
		</a-table>
		<a-table
			style="font-size: 12px; margin-top: 20px"
			:columns="insuranceColumns"
			size="small"
			:rowKey="(record, index) => index"
			:dataSource="insuRepayDataSource"
			:pagination="false"
			:scroll="{
				x: true
			}"
			:row-selection="insuRepayRowSelection"
			bordered
		>
			<span slot="title">
				{{ $t(`table.finance.lenging.insRepayDet`) }}
				<span v-if="lengingInfo.loanStatus < 1008 && pageState.fromPath.indexOf('collections') === -1">
					<!-- <template v-if="insuRepayRowSelection === null">
						<a-button type="primary" @click="selectRow('insuRepay')" style="margin-left: 10px">Undo</a-button>
					</template>
					<template v-else> -->
					<a-button type="primary" @click="repayUndo('insuRepay')" style="margin-left: 10px"> Undo </a-button>
					<!-- <a-button @click="insuRepayRowSelection = null" style="margin-left: 10px"> Cancel </a-button>
					</template> -->
				</span>
			</span>
			<span slot="otherFeePaid" slot-scope="obj">
				<a @click="SeeModal(obj)">{{ obj.otherFeeDetails }}</a>
			</span>
		</a-table>
		<a-table
			style="font-size: 12px; margin-top: 20px"
			:columns="emergencyColumns"
			size="small"
			:rowKey="(record, index) => index"
			:dataSource="emergencyRepayDataSource"
			:pagination="false"
			:scroll="{
				x: true
			}"
			:row-selection="emergencyRepayRowSelection"
			bordered
		>
			<span slot="title">
				{{ $t(`table.finance.lenging.repDet`) }}
				<span v-if="lengingInfo.loanStatus < 1008 && pageState.fromPath.indexOf('collections') === -1">
					<!-- <template v-if="emergencyRepayRowSelection === null">
						<a-button type="primary" @click="selectRow('emergencyRepay')" style="margin-left: 10px">Undo</a-button>
					</template>
					<template v-else> -->
					<a-button type="primary" @click="repayUndo('emergencyRepay')" style="margin-left: 10px"> Undo </a-button>
					<!-- <a-button @click="emergencyRepayRowSelection = null" style="margin-left: 10px"> Cancel </a-button>
					</template> -->
				</span>
			</span>
			<span slot="otherFeePaid" slot-scope="obj">
				<a @click="SeeModal(obj)">{{ obj.otherFeeDetails }}</a>
			</span>
		</a-table>
		<div class="remark">tips: {{ $t('table.finance.cloumRepayment.tips2') }}</div>
		<a-modal v-drag-modal v-model="FeeModal.show" :footer="false" width="600px" :title="$t(`table.finance.customerFee.cloum.otherFee`)">
			<a-table
				style="font-size: 12px"
				:columns="FeeModal.columns"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="FeeModal.dataSource"
				:pagination="false"
				bordered
			></a-table>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { apiRollback, apiRollbackSettlement } from '@/api/loan'
import SettlementModel from '@/views/businessComponents/SettlementModel'
export default {
	components: { SettlementModel },
	props: ['repaymentDetails', 'insuranceRepaymentDetails', 'emergencyRepaymentDetails', 'repayPlanList', 'lengingInfo'],
	data() {
		return {
			repayDataSource: [],
			repayRowSelection: {
				fixed: true,
				columnWidth: 50,
				onChange: (k, r) => {
					this.repaySelectRow = r
					this.$refs.settlementPaymentIds.selectPaymentIds('repay', [
						...new Set(
							r.map((item) => {
								return item.paymentId
							})
						)
					])
				}
			},
			repaySelectRow: [],
			insuRepayDataSource: [],
			insuRepayRowSelection: {
				fixed: true,
				columnWidth: 50,
				onChange: (k, r) => {
					this.insuRepaySelectRow = r
					this.$refs.settlementPaymentIds.selectPaymentIds('insuRepay', [
						...new Set(
							r.map((item) => {
								return item.paymentId
							})
						)
					])
				}
			},
			insuRepaySelectRow: [],
			emergencyRepayDataSource: [],
			emergencyRepayRowSelection: {
				fixed: true,
				columnWidth: 50,
				onChange: (k, r) => {
					this.emergencyRepaySelectRow = r
					this.$refs.settlementPaymentIds.selectPaymentIds('emergencyRepay', [
						...new Set(
							r.map((item) => {
								return item.paymentId
							})
						)
					])
				}
			},
			emergencyRepaySelectRow: [],
			FeeModal: {
				show: false,
				columns: [
					{
						title: this.$t('table.finance.customerFee.cloum.detail'),
						align: 'center',
						dataIndex: 'feeName'
					},
					{
						title: this.$t('table.finance.customerFee.cloum.amount'),
						align: 'center',
						dataIndex: 'amount',
						width: 100
					}
				],
				dataSource: []
			},
			paymentIdList: []
		}
	},
	computed: {
		...mapGetters(['pageState']),
		installmentsList() {
			return this.repayPlanList.map((item) => {
				return {
					installments: item.installments,
					duedate: item.duedate
				}
			})
		},
		emergencyColumns() {
			return [
				{
					// ID
					title: this.$t('table.finance.cloumRepayment.referenceId'),
					align: 'center',
					dataIndex: 'paymentId',
					width: 150,
					fixed: 'left'
				},
				{
					// 总额
					title: 'Total Repayment',
					dataIndex: 'total',
					align: 'center'
				},
				{
					// 期数
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments'
				},
				{
					// 时间
					title: this.$t('table.finance.cloumRepayment.time'),
					align: 'center',
					dataIndex: 'transactionTime'
				},
				{
					// 总额
					title: this.$t('table.finance.cloumRepayment.sum'),
					dataIndex: 'amount',
					align: 'center'
				},
				{
					// 本金
					title: this.$t('table.finance.cloumRepayment.amount'),
					dataIndex: 'principalPaid',
					align: 'center'
				},
				{
					// 罚息
					title: this.$t('table.finance.cloumRepayment.penalty'),
					dataIndex: 'penaltyPaid',
					align: 'center'
				},
				{
					// 月度罚息
					title: this.$t('table.finance.cloumRepayment.monthly'),
					dataIndex: 'interestPaid',
					align: 'center'
				}
			]
		},
		insuranceColumns() {
			return [
				{
					// ID
					title: this.$t('table.finance.cloumRepayment.referenceId'),
					align: 'center',
					dataIndex: 'paymentId',
					width: 150,
					fixed: 'left'
				},
				{
					// 总额
					title: 'Total Repayment',
					dataIndex: 'total',
					align: 'center'
				},
				{
					// 期数
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments'
				},
				{
					// 时间
					title: this.$t('table.finance.cloumRepayment.time'),
					align: 'center',
					dataIndex: 'transactionTime'
				},
				{
					// 总额
					title: this.$t('table.finance.cloumRepayment.sum'),
					dataIndex: 'amount',
					align: 'center'
				},
				{
					// 本金
					title: this.$t('table.finance.cloumRepayment.amount'),
					dataIndex: 'principalPaid',
					align: 'center'
				},
				{
					// 罚息
					title: this.$t('table.finance.cloumRepayment.penalty'),
					dataIndex: 'penaltyPaid',
					align: 'center'
				},
				{
					// 月度罚息
					title: this.$t('table.finance.cloumRepayment.monthly'),
					dataIndex: 'interestPaid',
					align: 'center'
				}
			]
		},
		repaymentColumns() {
			return [
				{
					// Reference ID
					title: this.$t('table.finance.cloumRepayment.referenceId'),
					align: 'center',
					dataIndex: 'paymentId',
					width: 150,
					fixed: 'left'
				},
				{
					// 总额
					title: 'Total Repayment',
					dataIndex: 'total',
					align: 'center'
				},
				{
					// 期数
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments'
				},
				{
					// 时间
					title: this.$t('table.finance.cloumRepayment.time'),
					align: 'center',
					dataIndex: 'transactionTime'
				},
				{
					// 总额
					title: this.$t('table.finance.cloumRepayment.sum'),
					dataIndex: 'amount',
					align: 'center'
				},
				{
					// 本金
					title: this.$t('table.finance.cloumRepayment.amount'),
					dataIndex: 'principalPaid',
					align: 'center'
				},
				{
					// GPS费用
					title: this.$t('table.finance.cloumRepayment.gpsFee'),
					dataIndex: 'trackingFeePaid',
					align: 'center',
					customRender: (v, o) => {
						return o.trackingFeeExtraPaid + '/' + v
					}
				},
				{
					title: this.$t('table.applyInfo.loanApplyLabel.maintenanceFee'),
					dataIndex: 'maintenanceFeePaid',
					align: 'center',
					customRender: (v, o) => {
						return o.maintenanceFeeExtraPaid + '/' + v
					}
				},
				{
					// 其他
					title: this.$t('table.finance.cloumRepayment.other'),
					align: 'center',
					scopedSlots: { customRender: 'otherFeePaid' }
				},
				{
					// 罚息
					title: this.$t('table.finance.cloumRepayment.penalty'),
					dataIndex: 'penaltyPaid',
					align: 'center',
					customRender: (v, o) => {
						return o.penaltyExtraPaid + '/' + v
					}
				},
				{
					// 月度罚息
					title: this.$t('table.finance.cloumRepayment.monthly'),
					dataIndex: 'interestPaid',
					align: 'center',
					customRender: (v, o) => {
						return o.interestExtraPaid + '/' + v
					}
				}
			]
		}
	},
	created() {
		this.$nextTick(() => {
			this.repayDataSource = this.getOtherFeeDetails(this.repaymentDetails)
			this.insuRepayDataSource = this.getOtherFeeDetails(this.insuranceRepaymentDetails)
			this.emergencyRepayDataSource = this.getOtherFeeDetails(this.emergencyRepaymentDetails)
		})
	},
	methods: {
		// 其他弹窗
		SeeModal(obj) {
			const otherFee = [
				{
					feeName: this.$t('table.finance.cloumRepayment.checkFee'),
					amount: obj.checkReturnFeePaid
				},
				{
					feeName: this.$t('table.finance.cloumRepayment.cost'),
					amount: obj.repossessionFeePaid
				},
				{
					feeName: this.$t('table.finance.cloumRepayment.costCode'),
					amount: obj.repossessionOrderFeePaid
				},
				{
					feeName: this.$t('table.finance.cloumRepayment.settle'),
					amount: obj.logbookFeePaid
				}
			]
			this.FeeModal.dataSource = obj.otherFee ? [...obj.otherFee.details, ...otherFee] : [...otherFee]
			this.FeeModal.show = true
		},
		getOtherFeeDetails(val) {
			val
				? val.map((item) => {
						item.otherFeeDetails =
							item.checkReturnFeePaid + item.repossessionFeePaid + item.repossessionOrderFeePaid + item.logbookFeePaid + item.otherFeePaid
				  })
				: []
			return val
		},
		// selectRow(type) {
		// 	this[`${type}RowSelection`] = {
		// 		fixed: true,
		// 		columnWidth: 50,
		// 		onChange: (k, r) => {
		// 			this[`${type}SelectRow`] = r
		// 		}
		// 	}
		// },
		repayUndo(type) {
			const _this = this
			this.$confirm({
				content: 'Are you sure to remove the repayments?',
				cancelText: 'Cancel',
				okText: 'Confirm',
				onOk: async () => {
					if (_this[`${type}SelectRow`].length === 0) {
						_this.$message.error('Select Empty')
						return false
					}
					const eventIds = _this[`${type}SelectRow`].map((item) => {
						return item.paymentId
					})
					await apiRollback({
						loanId: this.$route.query.id,
						eventIds: [...new Set(eventIds)]
					}).then(() => {
						_this.$message.success('Success')
						_this[`${type}DataSource`] = _this[`${type}DataSource`]
							.concat(_this[`${type}SelectRow`])
							.filter((v) => !_this[`${type}DataSource`].includes(v) || !_this[`${type}SelectRow`].includes(v))
						_this[`${type}RowSelection`] = null
						location.reload()
					})
				}
				// onCancel() {
				// 	_this[`${type}RowSelection`] = null
				// }
			})
		},
		earlSettlUndo(type) {
			const _this = this
			this.$confirm({
				content: 'Are you sure to remove the repayments?',
				cancelText: 'Cancel',
				okText: 'Confirm',
				onOk: async () => {
					await apiRollbackSettlement({
						type,
						loanId: this.$route.query.id
					}).then(() => {
						_this.$message.success('Success')
						location.reload()
					})
				},
				onCancel() {}
			})
		}
	}
}
</script>
<style scoped lang="less">
.remark {
	padding: 10px 0;
	font-size: 16px;
	color: #f56c6c;
}
.ant-input,
.ant-input-number,
.ant-select {
	width: 160px;
}
.purple-btn {
	background-color: blueviolet;
	border-color: blueviolet;
}
</style>
