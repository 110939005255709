<template>
	<a-tabs type="card" default-active-key="1">
		<a-tab-pane key="1" :tab="$t(`table.applyInfo.financialLabel.loanMap.${lengingInfo.loanType}`)">
			<a-descriptions bordered size="default">
				<a-descriptions-item :label="$t('table.loanInfo.caseInfo.userId')">
					{{ lengingInfo.casePrefix + '-' + lengingInfo.caseId }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.cloums.IdNumber')">
					{{ lengingInfo.identityNum }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.finance.cloum.name')">
					{{
						`${lengingInfo.firstName ? lengingInfo.firstName : ''} ${lengingInfo.middleName ? lengingInfo.middleName : ''} ${
							lengingInfo.lastName ? lengingInfo.lastName : ''
						}`
					}}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.mobile')">
					<div v-for="(item, index) in lengingInfo.mobiles" :key="index" style="margin-bottom: 5px">
						{{ item }}
					</div>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
					<span v-html="pageState.canvasCustomerInfo.right[2].value"></span>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.cloums.putAmount')">
					<a-statistic :precision="2" :value="lengingInfo.disburseAmount == null ? '' : lengingInfo.disburseAmount" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.finance.cloums.amount')">
					<a-statistic :precision="2" :value="lengingInfo.applyAmount == null ? '' : lengingInfo.applyAmount" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.finance.cloums.term')">
					{{ lengingInfo.installmentsNumbers }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.finance.cloums.returnTime')">
					{{ lengingInfo.disburseTime }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.cloums.overdue')">
					{{ lengingInfo.dueDays }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.cloums.caseStatus')">
					{{ $t(`table.finance.loanStatusMap.${lengingInfo.loanStatus}`) }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.cloums.loanClosedTime')">
					{{ lengingInfo.closeTime }}
				</a-descriptions-item>
			</a-descriptions>
		</a-tab-pane>
		<a-tab-pane :key="index + 1" :tab="$t(`table.applyInfo.financialLabel.loanMap.${info.loanType}`)" v-for="(info, index) in additionalLoanInfos">
			<a-descriptions bordered size="default">
				<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.phoneNumber')">
					{{ info.mobile }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
					{{ info.regNo }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.amountCloums.payAmount')">
					<a-statistic :precision="2" :value="info.disburseAmount == null ? '' : info.disburseAmount" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.cloums.loanAmount')">
					<a-statistic :precision="2" :value="info.loanAmount == null ? '' : info.loanAmount" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.cloums.loanTerm')">
					{{ info.loanTerm }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.cloums.loanDisbursedTime')">
					{{ info.loanDisburseTime }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.cloums.loanClosedTime')">
					{{ info.loanCloseTime }}
				</a-descriptions-item>
			</a-descriptions>
		</a-tab-pane>
	</a-tabs>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	props: ['lengingInfo', 'additionalLoanInfos'],
	computed: {
		...mapGetters(['pageState'])
	}
}
</script>
<style scoped lang="less">
.ant-form-item {
	margin-bottom: 10px;
}
.ant-form-item {
	display: flex;
}
.ant-form-item-control-wrapper {
	flex: 1;
}
</style>
