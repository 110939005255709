<template>
	<div class="box">
		<lenging-info
			:lengingInfo.sync="lengingInfo"
			:additionalLoanInfos="additionalLoanInfos"
			style="margin-bottom: 10px"
			v-if="lendShow"
		></lenging-info>
		<plan-list
			:repayPlan.sync="repayPlan"
			:insurancePlans.sync="insurancePlans"
			:emergencyPlans.sync="emergencyPlans"
			:overpaidDetail.sync="overpaidDetail"
			style="margin-bottom: 10px"
			:lengingInfo="lengingInfo"
			v-if="lendShow"
		></plan-list>
		<lenging-recorud
			:feeEvents="feeEvents"
			:insuranceFeeEvents="insuranceFeeEvents"
			:emergencyFeeEvents="emergencyFeeEvents"
			:installmentsNumbers.sync="lengingInfo.installmentsNumbers"
			style="margin-bottom: 10px"
			v-if="lendShow"
		></lenging-recorud>
		<detail
			:repaymentDetails.sync="repaymentDetails"
			:insuranceRepaymentDetails.sync="insuranceRepaymentDetails"
			:emergencyRepaymentDetails.sync="emergencyRepaymentDetails"
			:repayPlanList.sync="repayPlan"
			:lengingInfo="lengingInfo"
			:settlementParams="settlementParams"
			style="margin-bottom: 10px"
			v-if="lendShow"
		></detail>
		<case-details
			v-if="!noneCaseDetails"
			@getCaseDetailsData="getCaseDetailsData"
			:caseStates="'view_detail'"
			:notify="false"
			:parentNode="'lendingdetail'"
		></case-details>
	</div>
</template>
<script>
import LengingInfo from '@/views/customerManagement/lending/LengingInfo'
import PlanList from '@/views/customerManagement/lending/PlanList'
import LengingRecorud from '@/views/customerManagement/lending/LengingRecorud'
import Detail from '@/views/customerManagement/lending/Detail'
import CaseDetails from '@/views/businessComponents/CaseDetails'
import { apiCustomerDetail } from '@/api/check'
import { apiEmergencyInfo } from '@/api/emergencyLoan'
export default {
	data() {
		return {
			lengingInfo: {},
			additionalLoanInfos: [],
			repayPlan: [],
			feeEvents: [],
			repaymentDetails: [],
			insuranceFeeEvents: [],
			insurancePlans: [],
			insuranceRepaymentDetails: [],
			emergencyPlans: [],
			emergencyFeeEvents: [],
			emergencyRepaymentDetails: [],
			overpaidDetail: [],
			lendShow: false,
			loanInfo: {},
			loanInsuranceInfos: [],
			timer: null,
			noneCaseDetails: false,
			settlementParams: [],
			emergencyInfo: []
		}
	},
	components: {
		LengingInfo,
		PlanList,
		LengingRecorud,
		Detail,
		CaseDetails
	},
	methods: {
		getCaseDetailsData(val) {
			if (val === null) {
				this.noneCaseDetails = true
				return false
			}
			this.lengingInfo.cityCode = val.cityCode
			this.loanInfo = val.loanInfo
			this.loanInsuranceInfos = val.loanInsuranceInfos
			this.setCanvasCustomerInfo()
		},
		setCanvasCustomerInfo() {
			let registrationNumberList = []
			if (this.lengingInfo.carInfos) {
				registrationNumberList = this.lengingInfo.carInfos.map((element) => {
					return element.registrationNumber
				})
			}
			this.lengingInfo.registrationNumber = registrationNumberList.join('<br />')
			this.$store.commit('pageState/setCanvasCustomerInfo', {
				left: [
					{ name: 'Name:', value: `${this.lengingInfo.firstName} ${this.lengingInfo.middleName} ${this.lengingInfo.lastName}` },
					{ name: 'ID:', value: this.lengingInfo.identityNum },
					{ name: 'Phone Number:', value: this.lengingInfo.mobiles ? this.lengingInfo.mobiles.join('<br />') : '' },
					{ name: 'Effective Date:', value: this.lengingInfo.disburseTime.split(' ')[0] }
				],
				right: [
					{ value: this.lengingInfo.applyAmount },
					{ value: this.loanInfo.interestRate ? (this.loanInfo.interestRate * 100).toFixed(1) + '%' : '' },
					{ value: registrationNumberList.join('&nbsp;&nbsp;/&nbsp;&nbsp;') },
					{ value: this.lengingInfo.installmentsNumbers }
				],
				loanInsuranceInfos: this.loanInsuranceInfos.filter((item) => item.isReviewed === 1 && item.hasInsurance === 1).concat(this.emergencyInfo),
				cityCode: this.lengingInfo.cityCode
			})
		}
	},
	created() {
		this.$nextTick(() => {
			apiCustomerDetail({ loanId: this.$route.query.id }).then((res) => {
				this.lengingInfo = res.basicInfo
				this.additionalLoanInfos = res.additionalLoanInfos ? res.additionalLoanInfos : []
				this.repayPlan = res.plans
				this.feeEvents = res.feeEvents
				this.repaymentDetails = res.repaymentDetails
				this.insurancePlans = res.insurancePlans ? res.insurancePlans : []
				this.insuranceFeeEvents = res.insuranceFeeEvents ? res.insuranceFeeEvents : []
				this.insuranceRepaymentDetails = res.insuranceRepaymentDetails ? res.insuranceRepaymentDetails : []
				this.emergencyPlans = res.emergencyPlans ? res.emergencyPlans : []
				this.emergencyFeeEvents = res.emergencyFeeEvents ? res.emergencyFeeEvents : []
				this.emergencyRepaymentDetails = res.emergencyRepaymentDetails ? res.emergencyRepaymentDetails : []
				this.overpaidDetail = res.overpaidDetail ? res.overpaidDetail : []
				this.settlementParams = res.settlementParams ? res.settlementParams : []
				this.setCanvasCustomerInfo()
				this.lendShow = true
			})
			apiEmergencyInfo({ loanId: this.$route.query.id }).then((res) => {
				this.emergencyInfo = res
			})
		})
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (from.path !== '/') {
				vm.$store.commit('pageState/setFromPath', from.path)
			}
		})
	}
}
</script>
