<template>
	<div style="display: inline-block">
		<a-button type="primary" :style="{ backgroundColor: btnColor, borderColor: btnColor }" @click="earlSettlShow = true">{{ title }}</a-button>
		<a-modal v-drag-modal v-model="earlSettlShow" width="600px" :title="title">
			<template slot="footer">
				<a-button type="primary" v-if="!onlyDownload" @click="preRepayPlan">Preview</a-button>
				<a-button type="primary" v-if="onlyDownload" @click="downloadEarlySettlementForm">Download Early Settlement Form</a-button>
				<a-button type="primary" v-if="!onlyPreview && !onlyDownload" @click="earlSettl">Confirm</a-button>
			</template>
			<template v-if="type === 0">
				<p class="ant-form-item-label ant-form-item-label-left"><label>Choose the loan to early settled</label></p>
				<a-form-model-item :label-col="{ span: 14 }" labelAlign="left">
					<a-checkbox-group @change="setCheckedVal">
						<div v-for="settle in settlApiList" :key="settle.id">
							<a-checkbox :value="settle.loanType" style="margin-right: 40px">
								{{ $t(`table.applyInfo.financialLabel.loanMap.${settle.loanType}`) }}
							</a-checkbox>
							<div>
								Please select the period that early settled:
								<a-select v-model="settle.installment" style="width: 200px" @change="settleInstallment(settle.loanType, settle.installment)">
									<a-select-option :value="instal" v-for="instal in settle.installmentList" :key="instal">
										{{ instal }}
									</a-select-option>
								</a-select>
							</div>
						</div>
					</a-checkbox-group>
				</a-form-model-item>
			</template>
			<template v-if="type === 1">
				<p class="ant-form-item-label ant-form-item-label-left"
					><label>Please select the period that {{ onlyPreview ? 'early' : 'auction' }} settled</label></p
				>
				<a-form-model-item :label-col="{ span: 14 }" labelAlign="left">
					<a-select v-if="settlApiList[0]" v-model="activeInstallment" style="width: 200px" @change="activeSettleInstallment(activeInstallment)">
						<a-select-option :value="instal" v-for="instal in settlApiList[0].installmentList" :key="instal">
							{{ instal }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</template>
			<a-form-model-item v-if="!onlyDownload" label="NTSA Transfer Fee" :label-col="{ span: 11 }" labelAlign="left">
				<a-radio-group v-model="formModelData.inputNTSATransferFee" @change="needIsDiscount">
					<a-radio :value="1"> Input automatically </a-radio>
					<a-radio :value="0"> Do not input </a-radio>
				</a-radio-group>
			</a-form-model-item>
			<a-form-model-item v-if="!onlyDownload" label="Discount" :label-col="{ span: 4 }" labelAlign="left">
				<a-radio-group v-model="formModelData.isDiscount" @change="needIsDiscount">
					<a-radio :value="0"> No </a-radio>
					<a-radio :value="1"> Yes </a-radio>
				</a-radio-group>
				<div v-if="formModelData.isDiscount === 1" style="padding-top: 20px">
					<a-form-item label="Discount on Tracking Fee" :label-col="{ span: 11 }" labelAlign="left">
						<a-input-number
							:min="0"
							:max="apiDiscountData.discountTrackingAmount"
							v-model="formModelData.discountTrackingAmount"
							:precision="0"
							style="margin-right: 10px"
						/>
						{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountTrackingAmount}` }}
					</a-form-item>
					<a-form-item label="Discount on Loan Maintenance Fee" :label-col="{ span: 11 }" labelAlign="left">
						<a-input-number
							:min="0"
							:max="apiDiscountData.discountMaintenanceAmount"
							v-model="formModelData.discountMaintenanceAmount"
							:precision="0"
							style="margin-right: 10px"
						/>
						{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountMaintenanceAmount}` }}
					</a-form-item>
					<a-form-item label="Discount on Interest" :label-col="{ span: 11 }" labelAlign="left">
						<a-input-number
							:min="0"
							:max="apiDiscountData.discountInterestAmount"
							v-model="formModelData.discountInterestAmount"
							:precision="0"
							style="margin-right: 10px"
						/>
						{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountInterestAmount}` }}
					</a-form-item>
					<a-form-item label="Discount on Penalty" :label-col="{ span: 11 }" labelAlign="left">
						<a-input-number
							:min="0"
							:max="apiDiscountData.discountPenaltyAmount"
							v-model="formModelData.discountPenaltyAmount"
							:precision="0"
							style="margin-right: 10px"
						/>
						{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountPenaltyAmount}` }}
					</a-form-item>
					<a-form-item label="Discount on Other Fees" :label-col="{ span: 11 }" labelAlign="left">
						<a-input-number
							:min="0"
							:max="apiDiscountData.discountOtherAmount"
							v-model="formModelData.discountOtherAmount"
							:precision="0"
							style="margin-right: 10px"
						/>
						{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountOtherAmount}` }}
					</a-form-item>
				</div>
			</a-form-model-item>
		</a-modal>
		<a-modal
			v-if="!onlyDownload"
			v-drag-modal
			v-model="previewRepayTable"
			title="Repayments Preview"
			width="85%"
			:footer="false"
			:destroyOnClose="true"
		>
			<repayments-plan-list
				:repayPlan.sync="repayPlan"
				:insurancePlans.sync="insurancePlans"
				:emergencyPlans.sync="emergencyPlans"
				:overpaidDetail.sync="overpaidDetail"
				style="margin-bottom: 10px"
				:loanType="lengingInfo.loanType"
			></repayments-plan-list>
		</a-modal>
	</div>
</template>
<script>
import { exportWordDocx } from '@/utils/exportWordDocx'
import { apiEarlySettlementForm } from '@/api/collections'
import RepaymentsPlanList from '@/views/businessComponents/RepaymentsPlanList'
import { apiDisburseEarlyInfo, apiRollSettlementPreview, apiEarlySettlement, apiDiscountAmount } from '@/api/loan'
export default {
	props: ['title', 'btnColor', 'onlyPreview', 'onlyDownload', 'type', 'lengingInfo'],
	components: { RepaymentsPlanList },
	data() {
		return {
			formModelData: {
				settlementParams: [],
				type: this.type,
				paymentIds: [], //还款id，配合repayLoan一起对特定借据进行结清
				repayLoan: [], //还款类型，0所有，1车贷，2emergency 3: cash pap
				isDiscount: 0,
				discountInterestAmount: 0,
				discountPenaltyAmount: 0,
				discountTrackingAmount: 0,
				discountMaintenanceAmount: 0,
				discountOtherAmount: 0,
				inputNTSATransferFee: 1
			},
			apiDiscountData: {
				discountInterestAmount: 0, //可平账的利息
				discountPenaltyAmount: 0, //可平账的罚息
				discountTrackingAmount: 0, //可平账的gps费用
				discountMaintenanceAmount: 0, //可平账的maintenance金额
				discountOtherAmount: 0 //可平账的其他费用
			},
			isPreview: false,
			repayPlan: [],
			insurancePlans: [],
			emergencyPlans: [],
			overpaidDetail: [],
			earlSettlShow: false,
			previewRepayTable: false,
			earlSettlFormShow: false,
			settlApiList: [],
			repaySelectRow: [],
			insuRepaySelectRow: [],
			emergencyRepaySelectRow: [],
			activeInstallment: ''
		}
	},
	created() {
		this.$nextTick(() => {
			Promise.all([apiDisburseEarlyInfo({ loanId: this.$route.query.id }), apiDiscountAmount({ loanId: this.$route.query.id })]).then((res) => {
				this.settlApiList = res[0].map((item) => {
					item.installmentList = Array.from({ length: item.installment }, (v, k) => k + 1)
					item.checked = false
					item.installment = null
					return item
				})
				this.apiDiscountData = res[1]
			})
		})
	},
	methods: {
		settleInstallment(loanType, installment) {
			this.formModelData.settlementParams.map((item) => {
				if (item.loanType === loanType) {
					item.installment = installment
				}
			})
		},
		activeSettleInstallment(installment) {
			this.formModelData.settlementParams = [];
			this.formModelData.repayLoan = [];
			for (let index = 0; index < this.settlApiList.length; index++) {
				const item = this.settlApiList[index]
				this.formModelData.settlementParams.push({
					loanType: item.loanType,
					settlementLoanId: item.settlementLoanId,
					installment: installment
				})
				if (item.loanType === 7) {
					this.formModelData.repayLoan.push(1)
				} else {
					Number(item.loanType) > 4 ? this.formModelData.repayLoan.push(Number(item) - 3) : this.formModelData.repayLoan.push(1)
				}
			}
			// this.settlApiList.map((item) => {
			// 	this.formModelData.settlementParams.push({
			// 		loanType: item.loanType,
			// 		settlementLoanId: item.settlementLoanId,
			// 		installment: installment
			// 	})
			// 	if (item.loanType === 7) {
			// 		this.formModelData.repayLoan.push(1)
			// 	} else {
			// 		Number(item.loanType) > 4 ? this.formModelData.repayLoan.push(Number(item) - 3) : this.formModelData.repayLoan.push(1)
			// 	}
			// })
		},
		setCheckedVal(checkedValues) {
			this.formModelData.settlementParams = [];
			this.formModelData.repayLoan = [];		
			for (let index = 0; index < this.settlApiList.length; index++) {
				const item = this.settlApiList[index]
				item.checked = checkedValues.includes(item.loanType)
				if (item.checked) {
					this.formModelData.settlementParams.push({
						loanType: item.loanType,
						settlementLoanId: item.settlementLoanId,
						installment: item.installment
					})
					if (item.loanType === 7) {
						this.formModelData.repayLoan.push(1)
					} else {
						item.loanType > 4 ? this.formModelData.repayLoan.push(item - 3) : this.formModelData.repayLoan.push(1)
					}
				}
			}
			// this.settlApiList.map((item) => {
			// 	item.checked = checkedValues.includes(item.loanType)
			// 	if (item.checked) {
			// 		this.formModelData.settlementParams.push({
			// 			loanType: item.loanType,
			// 			settlementLoanId: item.settlementLoanId,
			// 			installment: item.installment
			// 		})
			// 		if (item.loanType === 7) {
			// 			this.formModelData.repayLoan.push(1)
			// 		} else {
			// 			item.loanType > 4 ? this.formModelData.repayLoan.push(item - 3) : this.formModelData.repayLoan.push(1)
			// 		}
			// 	}
			// 	return item
			// })
		},
		needIsDiscount() {
			if (this.formModelData.isDiscount === 0) {
				this.formModelData.discountInterestAmount = 0
				this.formModelData.discountPenaltyAmount = 0
				this.formModelData.discountTrackingAmount = 0
				this.formModelData.discountMaintenanceAmount = 0
				this.formModelData.discountOtherAmount = 0
			}
		},
		selectPaymentIds(type, paymentIds) {
			this[`${type}SelectRow`] = paymentIds
			this.formModelData.paymentIds = [...this.repaySelectRow, ...this.insuRepaySelectRow, ...this.emergencyRepaySelectRow]
		},
		downloadEarlySettlementForm() {
			let arrBool = true
			for (let index = 0; index < this.formModelData.settlementParams.length; index++) {
				if (!this.formModelData.settlementParams[index].installment) {
					this.$message.error('Please select the period')
					arrBool = false
					break
				}
			}
			if (!arrBool) {
				return arrBool
			}
			apiEarlySettlementForm({
				loanId: this.$route.query.id,
				settlementParams: this.formModelData.settlementParams
			}).then((res) => {
				const basePrefix = process.env.NODE_ENV === 'development' ? `/bccms/` : `/`
				const fullName = `${this.lengingInfo.firstName} ${this.lengingInfo.middleName} ${this.lengingInfo.lastName}`
				const insurance = res.insurances.map((ins) => {
					return {
						Amount: ins.installmentAmount.toFixed(2),
						Period: ins.period,
						Total: (ins.installmentAmount * ins.period).toFixed(2)
					}
				})
				const emergencies = res.emergencies.map((ins) => {
					const emeInterest =
						ins.interestAmount2 !== 0
							? [
									{ Rate: (ins.interestRate * 100).toFixed(1), Period: ins.interestPeriod, Amount: ins.interestAmount.toFixed(2) },
									{ Rate: (ins.interestRate2 * 100).toFixed(1), Period: ins.interestPeriod2, Amount: ins.interestAmount2.toFixed(2) }
							  ]
							: [{ Rate: (ins.interestRate * 100).toFixed(1), Period: ins.interestPeriod, Amount: ins.interestAmount.toFixed(2) }]
					return {
						Item: this.$t(`table.applyInfo.financialLabel.loanMap.${ins.loanType}`),
						PrincipalAmount: ins.principalAmount.toFixed(2),
						Interest: emeInterest,
						Penalties: ins.penalties.toFixed(2)
					}
				})
				const interest =
					res.interestAmount2 !== 0
						? [
								{ Rate: (res.interestRate * 100).toFixed(1), Period: res.interestPeriod, Amount: res.interestAmount.toFixed(2) },
								{ Rate: (res.interestRate2 * 100).toFixed(1), Period: res.interestPeriod2, Amount: res.interestAmount2.toFixed(2) }
						  ]
						: [{ Rate: (res.interestRate * 100).toFixed(1), Period: res.interestPeriod, Amount: res.interestAmount.toFixed(2) }]
				exportWordDocx(
					`${basePrefix}earlySettlementForm.docx`,
					{
						FullName: fullName,
						Item: this.$t(`table.applyInfo.financialLabel.loanMap.${res.loanType}`),
						PrincipalAmount: res.principalAmount.toFixed(2),
						Interest: interest,
						TrackingFee: res.trackingFee.toFixed(2),
						MaintenanceFee: res.maintenanceFee.toFixed(2),
						Penalties: res.penalties.toFixed(2),
						OtherCharges: res.otherCharges.toFixed(2),
						LogbookCharges: res.logbookCharges.toFixed(2),
						Insurance: insurance,
						TotalAmountToPaid: res.totalAmountToPaid.toFixed(2),
						AmountPaid: res.amountPaid.toFixed(2),
						TotalBalance: res.totalBalance.toFixed(2),
						Emergencies: emergencies
					},
					`${fullName} Early Settlement.docx`
				)
				this.earlSettlFormShow = false
			})
		},
		preRepayPlan() {
			let arrBool = true
			for (let index = 0; index < this.formModelData.settlementParams.length; index++) {
				if (!this.formModelData.settlementParams[index].installment) {
					this.$message.error('Please select the period')
					arrBool = false
					break
				}
			}
			if (!arrBool) {
				return arrBool
			}
			apiRollSettlementPreview({
				loanId: this.$route.query.id,
				...this.formModelData
			}).then((result) => {
				this.repayPlan = result.loanRepaymentPreviewVos
				this.insurancePlans = result.loanInsurancePreviewVos
				this.emergencyPlans = result.loanEmergencyPreviewVos
				this.overpaidDetail = result.overpaidDetail
				this.previewRepayTable = true
				this.isPreview = true
			})
		},
		earlSettl() {
			if (!this.isPreview) {
				this.$message.error('Please click preview first')
				return false
			}
			let arrBool = true
			for (let index = 0; index < this.formModelData.settlementParams.length; index++) {
				if (!this.formModelData.settlementParams[index].installment) {
					this.$message.error('Please select the period')
					arrBool = false
					break
				}
			}
			if (!arrBool) {
				return arrBool
			}
			this.$confirm({
				title: '',
				content: `Are you sure ${
					this.formModelData.type === 1 ? 'auction settlement' : 'early settlement'
				}?And please make sure you have uploaded all the charges before you give the ${
					this.formModelData.type === 1 ? 'auction settlement' : 'early settlement'
				}.`,
				onOk: () => {
					apiEarlySettlement({
						loanId: this.$route.query.id,
						...this.formModelData
					}).then(() => {
						this.earlSettlShow = false
						location.reload()
					})
				},
				onCancel() {}
			})
		}
	}
}
</script>
