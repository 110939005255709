<template>
	<a-card size="small">
		<template>
			<span slot="title">
				{{ $t(`table.finance.lenging.repayTracking`) }}
				<a-button type="primary" @click="loanStatementVisible = true" style="margin-left: 20px">Download Loan Statement</a-button>
				<a-button v-if="pageState.fromPath.indexOf('collections') != -1" type="primary" @click="exportWordDocx" style="margin-left: 20px"
					>Download Repayment Schedule</a-button
				>
				<settlement-model
					v-if="pageState.fromPath.indexOf('collections') != -1"
					title="Early Settlement"
					btnColor="orange"
					:onlyPreview="true"
					:type="1"
					:lengingInfo="lengingInfo"
					style="margin-left: 20px"
				></settlement-model>
				<a-button v-if="pageState.fromPath === '/home/finance/repaymentEntrty'" type="primary" @click="LuFee" style="margin-left: 20px">{{
					$t('table.finance.customerFee.enterFees')
				}}</a-button>
			</span>
		</template>
		<a-table
			style="font-size: 12px"
			:columns="repayColumns"
			size="small"
			:rowKey="(record, index) => index"
			:dataSource="repayPlanDataSource"
			:pagination="false"
			:scroll="{
				x: true
			}"
			bordered
		>
			<b slot="title">
				{{
					!$Util.isEmpty(lengingInfo.loanType) ? $t(`table.applyInfo.financialLabel.loanMap.${lengingInfo.loanType}`) : 'logbook Loan/Asset Finance'
				}}
				<a-button
					v-if="pageState.fromPath.indexOf('finance') != -1"
					type="primary"
					@click="showDueDateModal"
					style="margin-left: 20px"
					:disabled="lengingInfo.hadChangeDueDay === 1"
					>Change due date</a-button
				>
				<a-button
					v-if="this.lengingInfo.loanType === 4 && pageState.fromPath.indexOf('finance') != -1"
					type="primary"
					@click="interestModal.show = true"
					style="margin-left: 20px"
					>Change interest</a-button
				>
			</b>
			<span slot="title2">
				<p class="p_list">
					{{ $t('table.finance.cloumRepayment.should') }}
				</p>
				<p class="p_list">
					{{ $t('table.finance.cloumRepayment.already') }}
				</p>
				<p class="p_list">
					{{ $t('table.finance.cloumRepayment.dscountAmount') }}
				</p>
				<p class="p_list">
					{{ $t('table.finance.cloumRepayment.tobe') }}
				</p>
			</span>
			<span slot="other" slot-scope="obj">
				<p class="link" @click="ShowModal(obj, 'Due')">{{ Number(obj.otherFeeDueTal).toFixed(2) }}</p>
				<p class="link" @click="ShowModal(obj, 'Paid')">{{ Number(obj.otherFeePaidTal).toFixed(2) }}</p>
				<!--  class="link" @click="ShowModal(obj, 'ExtraPaid')" -->
				<p>{{ Number(obj.otherFeeExtraPaid).toFixed(2) }}</p>
				<p class="link" @click="ShowModal(obj, 'Outstanding')">{{ Number(obj.otherFeeOutstandingTal).toFixed(2) }}</p>
			</span>
		</a-table>
		<a-tabs v-if="insurancePlanDataSource.length > 0" :defaultActiveKey="0" type="card" :animated="true" style="margin-top: 20px">
			<a-tab-pane :tab="'IPF ' + (insurancePlanDataSource.length - index)" v-for="(insurInfo, index) in insurancePlanDataSource" :key="index">
				<a-table
					style="font-size: 12px; margin: 10px 0"
					:columns="insuColumns"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="insurInfo"
					:pagination="false"
					:scroll="{
						x: true
					}"
					bordered
				>
					<b slot="title">{{ $t(`table.applyInfo.financialLabel.insurancePreFund`) }}</b>
					<span slot="title2">
						<p class="p_list">
							{{ $t('table.finance.cloumRepayment.should') }}
						</p>
						<p class="p_list">
							{{ $t('table.finance.cloumRepayment.already') }}
						</p>
						<p class="p_list">
							{{ $t('table.finance.cloumRepayment.tobe') }}
						</p>
					</span>
				</a-table>
			</a-tab-pane>
		</a-tabs>
		<a-tabs v-if="emergencyPlanDataSource.length > 0" :defaultActiveKey="0" type="card" :animated="true" style="margin-top: 20px">
			<a-tab-pane
				:tab="$t(`table.applyInfo.financialLabel.loanMap.${emergencyInfo[0].loanType}`)"
				v-for="(emergencyInfo, index) in emergencyPlanDataSource"
				:key="index"
			>
				<a-table
					style="font-size: 12px; margin: 10px 0"
					:columns="insuColumns"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="emergencyInfo"
					:pagination="false"
					:scroll="{
						x: true
					}"
					bordered
				>
					<span slot="title2">
						<p class="p_list">
							{{ $t('table.finance.cloumRepayment.should') }}
						</p>
						<p class="p_list">
							{{ $t('table.finance.cloumRepayment.already') }}
						</p>
						<p class="p_list">
							{{ $t('table.finance.cloumRepayment.tobe') }}
						</p>
					</span>
				</a-table>
			</a-tab-pane>
		</a-tabs>
		<a-table
			:columns="detailsColumns"
			size="small"
			:rowKey="(record, index) => index"
			:dataSource="overpaidDetail.repays"
			:pagination="false"
			bordered
			class="border-right"
		>
			<b slot="title">{{ $t(`table.applyInfo.financialLabel.overpaymentDetails`) }}</b>
			<span slot="receipt" slot-scope="obj">
				<template v-if="obj.transactionFiles">
					<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(obj.transactionFiles.split('?')[0].split('.').pop().toLowerCase())">
						<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, obj.transactionFiles)" alt="" width="100" height="50" />
					</viewer>
					<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.transactionFiles)" target="_blank">{{
						obj.transactionFiles.split('/')[obj.transactionFiles.split('/').length - 1]
					}}</a>
				</template>
				<template v-else-if="obj.transactionFiless">
					<span v-for="(img, index) in obj.transactionFiless" :key="index">
						<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(img.split('?')[0].split('.').pop().toLowerCase())">
							<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, img)" alt="" width="100" height="50" />
						</viewer>
						<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, img)" target="_blank">{{
							img.split('/')[img.split('/').length - 1]
						}}</a>
						<br />
					</span>
				</template>
				<template v-else>-</template>
			</span>
		</a-table>
		<a-modal v-drag-modal v-model="lookFeeModal.show" :footer="false" width="600px" :title="$t(`table.finance.customerFee.cloum.otherFee`)">
			<a-table
				style="font-size: 12px"
				:columns="lookFeeModal.columns"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="lookFeeModal.dataSource"
				:pagination="false"
				bordered
			></a-table>
		</a-modal>
		<a-modal v-model="loanStatementVisible" title="Download Loan Statement" @ok="onExport">
			<a-checkbox-group v-model="checkedStatement" :options="optionsStatement" />
		</a-modal>
		<a-modal v-drag-modal v-model="FeeModal.show" :title="$t('menus.financeMenu.customerFee')" :destroyOnClose="true" width="60%" :footer="false">
			<a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" class="from">
				<a-form-model-item>
					<a-select v-model="FeeModal.type" @change="ChangeType">
						<a-select-option :value="item.value" v-for="item in FeeModal.selectList" :disabled="item.disabled" :key="item.value">{{
							$t(`table.finance.customerFee.tabs.${item.value}`)
						}}</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-card :title="$t('table.finance.customerFee.tabs.1')" size="small" v-if="FeeModal.type == 1">
					<a-form-model-item :label="$t('table.finance.customerFee.fee')">
						<a-input-number v-model="FeeModal.amount" :precision="0" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.customerFee.cloum.time')">
						<a-date-picker
							v-model="FeeModal.transactionTime"
							format="YYYY-MM-DD HH:mm:ss"
							:show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
						/>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.customerFee.periodInput')">
						<a-select v-model="FeeModal.checkInstallments">
							<a-select-option :value="item" v-for="item in installmentsOptions" :key="item">{{ item }}</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.customerFee.bankName')">
						<a-input v-model="FeeModal.bankName" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.customerFee.voucher')">
						<UploadPreviewFile :uploadFilePath.sync="FeeModal.certificate" :folderPath="FeeModal.id"></UploadPreviewFile>
					</a-form-model-item>
				</a-card>
				<a-card :title="$t(`table.finance.customerFee.tabs.${FeeModal.type}`)" size="small" v-if="FeeModal.type == 3 || FeeModal.type == 2">
					<a-form-model-item :label="$t('table.finance.customerFee.fee')">
						<a-input-number v-model="FeeModal.amount" :precision="0" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.customerFee.cloum.time')">
						<a-date-picker
							v-model="FeeModal.transactionTime"
							format="YYYY-MM-DD HH:mm:ss"
							:show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
						/>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.customerFee.periodInput')">
						<a-select v-model="FeeModal.checkInstallments">
							<a-select-option :value="item" v-for="item in installmentsOptions" :key="item">{{ item }}</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.customerFee.invoice')">
						<UploadPreviewFile :uploadFilePath.sync="FeeModal.certificate" :folderPath="FeeModal.id"></UploadPreviewFile>
					</a-form-model-item>
				</a-card>
				<a-card :title="$t('table.finance.customerFee.tabs.4')" size="small" v-if="FeeModal.type == 4">
					<a-form-model-item>
						{{ $t('table.finance.customerFee.periodInput') }}：
						<a-select v-model="FeeModal.checkInstallments">
							<a-select-option :value="item" v-for="item in installmentsOptions" :key="item">{{ item }}</a-select-option>
						</a-select>
						<a-button type="primary" @click="AddData" style="margin-left: 20px">{{ $t('page.add') }}</a-button>
					</a-form-model-item>
					<a-table
						style="font-size: 12px"
						:columns="FeeModal.columns"
						size="small"
						:rowKey="(record, index) => index"
						:dataSource="FeeModal.dataSource"
						:pagination="false"
						bordered
					>
						<span slot="detail" slot-scope="obj">
							<a-input v-model="obj.detail" style="width: 100%" />
						</span>
						<span slot="amount" slot-scope="obj">
							<a-input-number v-model="obj.amount" style="width: 100%" :precision="0" />
						</span>
						<span slot="transactionTime" slot-scope="obj">
							<a-date-picker
								v-model="obj.transactionTime"
								style="width: 100%"
								format="YYYY-MM-DD HH:mm:ss"
								:show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
							/>
						</span>
						<span slot="checkInstallments" slot-scope="obj">
							<a-select v-model="obj.checkInstallments" style="width: 100%">
								<a-select-option :value="item" v-for="item in installmentsOptions" :key="item">{{ item }}</a-select-option>
							</a-select>
						</span>
						<span slot="action2" slot-scope="text, obj, index">
							<a @click="Del(index)">{{ $t('page.delete') }}</a>
						</span>
					</a-table>
					<a-form-model-item :label="$t('table.finance.customerFee.voucher')">
						<UploadPreviewFile :uploadFilePath.sync="FeeModal.certificates" :folderPath="FeeModal.id"></UploadPreviewFile>
					</a-form-model-item>
				</a-card>
				<a-form-model-item>
					<a-button type="primary" @click="SaveFrom">
						{{ $t('page.submit') }}
					</a-button>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<a-modal v-model="dueDateModal.show" title="Change due date" @ok="changeDueDay" width="600px">
			<a-form-model-item label="Please select the peroid you want to start changing due date" :label-col="{ span: 24 }" labelAlign="left">
				<a-select v-model="dueDateModal.installments" style="width: 200px">
					<a-select-option :value="item" v-for="item in dueDateInstList" :key="item">{{ item }}</a-select-option>
				</a-select>
				<a-date-picker
					v-model="dueDateModal.changedDueDate"
					:disabled-date="disabledDate"
					format="YYYY-MM-DD HH:mm:ss"
					:show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
					style="margin-left: 10px"
				/>
			</a-form-model-item>
			<a-form-model-item label="Give the 5% of principal as a charge to which period" :label-col="{ span: 24 }" labelAlign="left">
				<a-select v-model="dueDateModal.feePeriod" style="width: 200px">
					<a-select-option :value="item" v-for="item in dueDateInstList" :key="item">{{ item }}</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="Upload Form" :label-col="{ span: 24 }" labelAlign="left">
				<UploadPreviewFile :uploadFilePath.sync="dueDateModal.receipt" :folderPath="$route.query.id"></UploadPreviewFile>
			</a-form-model-item>
		</a-modal>
		<a-modal v-model="interestModal.show" title="Change interest" @ok="changeInterest" width="600px">
			<a-form-model-item label="Please choose the period to change the lnterest rate" :label-col="{ span: 24 }" labelAlign="left">
				<a-select v-model="interestModal.installments" style="width: 200px">
					<a-select-option :value="item" v-for="item in laveInstList" :key="item">{{ item }}</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="Please inpuy the new lnterest rate" :label-col="{ span: 24 }" labelAlign="left">
				<a-input-number
					v-model="interestModal.interest"
					:precision="4"
					:step="0.01"
					:min="0"
					:formatter="(value) => `${value * 100}%`"
					:parser="(value) => Number(value.replace('%', '')) / 100"
				/>
			</a-form-model-item>
		</a-modal>
		<html-to-canvas type="finance" :canvasDataSource="canvasDataSource" ref="financeLoanStatement"></html-to-canvas>
		<!-- v-if="lengingInfo.loanStatus < 1005" -->
		<html-to-canvas
			type="collections"
			:canvasDataSource="canvasDataSource"
			:loanStatus="lengingInfo.loanStatus"
			ref="collectionsLoanStatement"
		></html-to-canvas>
	</a-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { exportWordDocx } from '@/utils/exportWordDocx'
import { apiFeeInput } from '@/api/check'
import { apiChangeDueDay, apiChangeInterest } from '@/api/loan'
import { apiRepaymentSchedule } from '@/api/collections'
import SettlementModel from '@/views/businessComponents/SettlementModel'
import moment from 'moment'
export default {
	components: { SettlementModel },
	props: ['lengingInfo', 'repayPlan', 'insurancePlans', 'emergencyPlans', 'overpaidDetail'],
	data() {
		return {
			canvasDataSource: [],
			lookFeeModal: {
				show: false,
				columns: [
					{
						// 费用金额
						title: this.$t('table.finance.customerFee.cloum.detail'),
						align: 'center',
						dataIndex: 'feeName'
					},
					{
						// 费用金额
						title: this.$t('table.finance.customerFee.cloum.amount'),
						align: 'center',
						dataIndex: 'amount',
						width: 100
					}
				],
				dataSource: []
			},
			FeeModal: {
				selectList: [
					{ value: 1, disabled: false },
					{ value: 2, disabled: false },
					{ value: 3, disabled: false },
					{ value: 4, disabled: false }
				],
				show: false,
				id: '',
				type: 4,
				bankName: '',
				dataSource: [],
				columns: [
					{
						// 费用明细
						title: this.$t('table.finance.customerFee.cloum.detail'),
						scopedSlots: { customRender: 'detail' },
						align: 'center',
						width: '25%'
					},
					{
						// 金额
						title: this.$t('table.finance.customerFee.cloum.amount'),
						scopedSlots: { customRender: 'amount' },
						align: 'center',
						width: '25%'
					},
					{
						// 金额
						title: this.$t('table.finance.customerFee.cloum.time'),
						scopedSlots: { customRender: 'transactionTime' },
						align: 'center'
					},
					// {
					// 	// 金额
					// 	title: this.$t('table.finance.customerFee.periodInput'),
					// 	scopedSlots: { customRender: 'checkInstallments' },
					// 	align: 'center',
					// 	width: '15%'
					// },
					{
						// 操作
						title: this.$t('operate.operate'),
						scopedSlots: { customRender: 'action2' },
						align: 'center',
						width: '15%'
					}
				],
				amount: '',
				transactionTime: '',
				certificate: '',
				certificates: [],
				checkInstallments: '',
				installmentsNumbers: ''
			},
			repayPlanDataSource: [],
			insurancePlanDataSource: [],
			emergencyPlanDataSource: [],
			loanStatementVisible: false,
			checkedStatement: [],
			dueDateModal: {
				show: false,
				loanId: this.$route.query.id,
				installments: 1,
				changedDueDate: '',
				feePeriod: 1,
				receipt: ''
			},
			interestModal: {
				show: false,
				loanId: this.$route.query.id,
				installments: 1,
				interest: 0.0108
			}
		}
	},
	computed: {
		...mapGetters(['pageState']),
		installmentsOptions() {
			let arr = []
			for (let index = 1; index < this.lengingInfo.installmentsNumbers + 1; index++) {
				arr.push(index)
			}
			return arr
		},
		dueDateInstList() {
			const dueDateInst = []
			this.repayPlan.forEach((element) => {
				if (moment(new Date()).isBefore(element.duedate)) {
					dueDateInst.push(element.installments)
				}
			})
			return dueDateInst
		},
		laveInstList() {
			const dueDateInst = []
			this.repayPlan.forEach((element) => {
				dueDateInst.push(element.installments)
			})
			return dueDateInst
		},
		repayColumns() {
			const repayColumnsList = [
				{
					// 期数
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments',
					width: 60,
					fixed: 'left'
				},
				{
					title: 'Scheduled Repayment Date',
					dataIndex: 'duedate',
					align: 'center',
					width: 120,
					fixed: 'left'
				},
				{
					title: 'Actual Repayment Date',
					dataIndex: 'actualRepayDate',
					align: 'center',
					width: 120,
					fixed: 'left'
				},
				{
					title: '',
					scopedSlots: { customRender: 'title2' },
					align: 'center',
					width: 180,
					fixed: 'left'
				},
				{
					// 本金
					title: this.$t('table.finance.cloumRepayment.amount'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.principalDue.toFixed(2)}</p> <p>{obj.principalPaid.toFixed(2)}</p>
								<p>0.00</p> <p>{obj.principalOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				},
				{
					// 其他
					title: this.$t('table.finance.cloumRepayment.other'),
					scopedSlots: { customRender: 'other' },
					align: 'center'
				},
				{
					// 罚息
					title: this.$t('table.finance.cloumRepayment.penalty'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.penaltyDue.toFixed(2)}</p> <p>{obj.penaltyPaid.toFixed(2)}</p>
								<p>{obj.penaltyExtraPaid.toFixed(2)}</p> <p>{obj.penaltyOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				},
				{
					// 月度罚息
					title: this.$t('table.finance.cloumRepayment.monthly'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.interestDue.toFixed(2)}</p> <p>{obj.interestPaid.toFixed(2)}</p>
								<p>{obj.interestExtraPaid.toFixed(2)}</p> <p>{obj.interestOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				},
				{
					// 总额
					title: this.$t('table.finance.cloumRepayment.sum'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.totalDue.toFixed(2)}</p> <p>{obj.totalPaid.toFixed(2)}</p> <p>{obj.totalExtraPaid.toFixed(2)}</p>
								<p>{obj.totalOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				}
			]
			if (this.lengingInfo.loanType === 4) {
				repayColumnsList.splice(5, 0, {
					// GPS费用
					title: this.$t('table.finance.cloumRepayment.gpsFee'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.trackingFeeDue.toFixed(2)}</p> <p>{obj.trackingFeePaid.toFixed(2)}</p>
								<p>{obj.trackingFeeExtraPaid.toFixed(2)}</p> <p>{obj.trackingFeeOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				})
			}
			if (this.lengingInfo.loanType !== 3 && this.lengingInfo.loanType !== 4) {
				repayColumnsList.splice(
					5,
					0,
					{
						// GPS费用
						title: this.$t('table.finance.cloumRepayment.gpsFee'),
						align: 'center',
						customRender: (obj) => {
							return (
								<span>
									<p>{obj.trackingFeeDue.toFixed(2)}</p> <p>{obj.trackingFeePaid.toFixed(2)}</p>
									<p>{obj.trackingFeeExtraPaid.toFixed(2)}</p> <p>{obj.trackingFeeOutstanding.toFixed(2)}</p>
								</span>
							)
						}
					},
					{
						title: this.$t('table.applyInfo.loanApplyLabel.maintenanceFee'),
						align: 'center',
						customRender: (obj) => {
							return (
								<span>
									<p>{obj.maintenanceFeeDue.toFixed(2)}</p> <p>{obj.maintenanceFeePaid.toFixed(2)}</p>
									<p>{obj.maintenanceFeeExtraPaid.toFixed(2)}</p> <p>{obj.maintenanceFeeOutstanding.toFixed(2)}</p>
								</span>
							)
						}
					}
				)
			}
			return repayColumnsList
		},
		insuColumns() {
			return [
				{
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments',
					width: 60,
					fixed: 'left'
				},
				{
					title: 'Scheduled Repayment Date',
					dataIndex: 'duedate',
					align: 'center',
					width: 150,
					fixed: 'left'
				},
				{
					title: 'Actual Repayment Date',
					dataIndex: 'actualRepayDate',
					align: 'center',
					width: 150,
					fixed: 'left'
				},
				{
					scopedSlots: { customRender: 'title2' },
					align: 'center',
					width: 180,
					fixed: 'left'
				},
				{
					title: this.$t('table.finance.cloumRepayment.amount'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.principalDue.toFixed(2)}</p> <p>{obj.principalPaid.toFixed(2)}</p> <p>{obj.principalOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				},
				{
					title: this.$t('table.finance.cloumRepayment.penalty'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.penaltyDue.toFixed(2)}</p> <p>{obj.penaltyPaid.toFixed(2)}</p> <p>{obj.penaltyOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				},
				{
					title: this.$t('table.finance.cloumRepayment.monthly'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.interestDue.toFixed(2)}</p> <p>{obj.interestPaid.toFixed(2)}</p> <p>{obj.interestOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				},
				{
					title: this.$t('table.finance.cloumRepayment.sum'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.totalDue.toFixed(2)}</p> <p>{obj.totalPaid.toFixed(2)}</p> <p>{obj.totalOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				}
			]
		},
		detailsColumns() {
			return [
				{
					title: this.$t('table.finance.cloumRepayment.referenceId'),
					align: 'center',
					dataIndex: 'paymentId'
				},
				{
					title: this.$t('table.finance.cloumRepayment.time'),
					align: 'center',
					dataIndex: 'transactionDate'
				},
				{
					title: this.$t('table.finance.cloumRepayment.sum'),
					dataIndex: 'amount',
					align: 'center'
				},
				{
					title: this.$t('table.finance.lenging.cloums.receipt'),
					align: 'center',
					scopedSlots: { customRender: 'receipt' }
				},
				{
					title: this.$t(`table.applyInfo.financialLabel.totalRefundAmount`),
					align: 'center',
					customRender: (text, row, index) => {
						if (index === 0) {
							return {
								children: <span>{this.overpaidDetail.totalRefundAmount}</span>,
								attrs: { rowSpan: this.overpaidDetail.repays.length }
							}
						} else {
							return {
								attrs: { rowSpan: 0 }
							}
						}
					}
				},
				{
					title: this.$t(`table.applyInfo.financialLabel.remainingOverpaymentAmount`),
					align: 'center',
					customRender: (text, row, index) => {
						if (index === 0) {
							return {
								children: <span>{this.overpaidDetail.amount}</span>,
								attrs: { rowSpan: this.overpaidDetail.repays.length }
							}
						} else {
							return {
								attrs: { rowSpan: 0 }
							}
						}
					}
				}
			]
		},
		optionsStatement() {
			// if (this.lengingInfo.loanStatus < 1005) {
			return [
				{ label: 'Loan Statement for Finance', value: 'finance' },
				{ label: 'Loan Statement for Collections', value: 'collections' }
			]
			// } else {
			// 	return [{ label: 'Loan Statement for Finance', value: 'finance' }]
			// }
		}
	},
	methods: {
		moment: moment,
		disabledDate(current) {
			return current && current < this.moment().endOf('day')
		},
		// 其他弹窗
		ShowModal(obj, type) {
			let params = { loanId: this.id }
			if (obj.installments == 'Total') {
			} else {
				params.installments = obj.installments
			}
			const otherFee = [
				{
					feeName: this.$t('table.finance.cloumRepayment.checkFee'),
					amount: obj[`checkReturnFee${type}`]
				},
				{
					feeName: this.$t('table.finance.cloumRepayment.cost'),
					amount: obj[`repossessionFee${type}`]
				},
				{
					feeName: this.$t('table.finance.cloumRepayment.costCode'),
					amount: obj[`repossessionOrderFee${type}`]
				},
				{
					feeName: this.$t('table.finance.cloumRepayment.settle'),
					amount: obj[`logbookFee${type}`]
				}
			]
			this.lookFeeModal.dataSource = obj.otherFee ? [...obj.otherFee.details, ...otherFee] : [...otherFee]
			this.lookFeeModal.show = true
		},
		onExport() {
			if (this.checkedStatement.length === 0) {
				this.$message.error('Select Empty')
				return false
			}
			this.checkedStatement.includes('finance') ? this.$refs.financeLoanStatement.onExport() : ''
			this.checkedStatement.includes('collections') ? this.$refs.collectionsLoanStatement.onExport() : ''
			this.loanStatementVisible = false
			this.checkedStatement = []
		},
		// 客户录入费用类型改变
		ChangeType() {
			this.FeeModal.certificates = []
			this.FeeModal.certificate = ''
			this.FeeModal.amount = ''
			this.FeeModal.transactionTime = ''
			this.FeeModal.bankName = ''
			this.FeeModal.checkInstallments = ''
			this.FeeModal.dataSource = []
		},
		// 删除
		Del(idx) {
			this.FeeModal.dataSource.splice(idx, 1)
		},
		AddData() {
			this.FeeModal.dataSource.push({
				detail: '',
				amount: '',
				transactionTime: ''
			})
		},
		// 录入客户费用
		LuFee() {
			if (this.lengingInfo.loanStatus > 1004) {
				this.$message.error('Loan Cleared!')
				return false
			}
			this.FeeModal.id = this.lengingInfo.loanId
			this.FeeModal.type = 4
			this.FeeModal.dataSource = []
			this.FeeModal.amount = ''
			this.FeeModal.transactionTime = ''
			this.FeeModal.certificates = []
			this.FeeModal.bankName = ''
			this.FeeModal.checkInstallments = ''
			this.FeeModal.installmentsNumbers = this.lengingInfo.installmentsNumbers
			this.FeeModal.show = true
		},
		// 表单提交
		SaveFrom() {
			let params = {
				loanId: this.FeeModal.id,
				installments: this.FeeModal.checkInstallments
			}
			if (this.FeeModal.type == 1) {
				params.feeType = 'paid_fail'
				params.amount = this.FeeModal.amount
				params.transactionTime = this.moment(this.FeeModal.transactionTime).format('YYYY-MM-DD HH:mm:ss')
				params.bankName = this.FeeModal.bankName
				params.certificate = this.FeeModal.certificate
				if (!params.amount) {
					this.$message.error('amount is empty!')
					return false
				} else if (!params.transactionTime) {
					this.$message.error('transactionTime is empty!')
					return false
				} else if (!params.bankName) {
					this.$message.error('bankname is empty!')
					return false
				} else if (!params.installments) {
					this.$message.error('Period is empty!')
					return false
				}
				// else if (!params.certificate) {
				// 	this.$message.error('file is empty!')
				// 	return false
				// }
			} else if (this.FeeModal.type == 2 || this.FeeModal.type == 3) {
				params.feeType = this.FeeModal.type == 2 ? 'collect_car' : 'transfer'
				params.amount = this.FeeModal.amount
				params.certificate = this.FeeModal.certificate
				params.transactionTime = this.moment(this.FeeModal.transactionTime).format('YYYY-MM-DD HH:mm:ss')
				if (!params.amount) {
					this.$message.error('amount is empty!')
					return false
				} else if (!params.transactionTime) {
					this.$message.error('transactionTime is empty!')
					return false
				} else if (!params.installments) {
					this.$message.error('Period is empty!')
					return false
				}
				// else if (!params.certificate) {
				// 	this.$message.error('file is empty!')
				// 	return false
				// }
			} else if (this.FeeModal.type == 4) {
				params.feeType = 'others'
				params.certificates = this.FeeModal.certificates
				params.details = []
				delete params.installments
				this.FeeModal.dataSource.map((item) => {
					params.details.push({
						feeName: item.detail,
						feeAmount: item.amount,
						transactionTime: this.moment(item.transactionTime).format('YYYY-MM-DD HH:mm:ss'),
						installments: this.FeeModal.checkInstallments
					})
				})
				if (!this.FeeModal.checkInstallments) {
					this.$message.error('Period is empty!')
					return false
				} else if (params.details.length == 0) {
					this.$message.error('details is empty!')
					return false
				}
				// else if (params.certificates.length === 0) {
				// 	this.$message.error('file is empty!')
				// 	return false
				// }
			}
			this.$confirm({
				title: '',
				content: this.$t('table.applyInfo.msgMap.msg1'),
				onOk: () => {
					apiFeeInput(params).then((res) => {
						this.$message.success('success')
						this.FeeModal.show = false
					})
				},
				onCancel() {}
			})
		},
		showDueDateModal() {
			this.dueDateModal = {
				show: true,
				loanId: this.$route.query.id,
				installments: this.dueDateInstList[0],
				changedDueDate: '',
				feePeriod: this.dueDateInstList[0],
				receipt: ''
			}
		},
		changeDueDay() {
			if (!this.dueDateModal.receipt) {
				this.$message.error('Upload Form is empty!')
				return false
			}
			this.$confirm({
				content: "Do you sure to change the due date? You can't undo this operation.",
				onOk: () => {
					this.dueDateModal.changedDueDate = this.moment(this.dueDateModal.changedDueDate).format('YYYY-MM-DD HH:mm:ss')
					apiChangeDueDay({ ...this.dueDateModal }).then((res) => {
						this.$message.success('success')
						this.dueDateModal.show = false
						location.reload()
					})
				},
				onCancel() {}
			})
		},
		changeInterest() {
			if (!this.interestModal.interest) {
				this.$message.error('Interest is empty!')
				return false
			}
			this.$confirm({
				content: 'Do you sure to change interest?',
				onOk: () => {
					apiChangeInterest({ ...this.interestModal }).then((res) => {
						this.$message.success('success')
						this.interestModal.show = false
						location.reload()
					})
				},
				onCancel() {}
			})
		},
		exportWordDocx() {
			apiRepaymentSchedule({
				loanId: this.$route.query.id
			}).then((res) => {
				const basePrefix = process.env.NODE_ENV === 'development' ? `/bccms/` : `/`
				let IPFItem = []
				if (res.insurances.length > 0) {
					res.insurances.forEach((element) => {
						IPFItem.push({
							Item: `IPF Interest: ${(element.interest * 100).toFixed(1)}%`
						})
						IPFItem.push({
							Item: `IPF Principal: Ksh ${element.principalAmount.toFixed(2)}`
						})
					})
				}
				if (res.emergencies && res.emergencies.length > 0) {
					res.emergencies.forEach((element) => {
						IPFItem.push({
							Item: `${this.$t(`table.applyInfo.financialLabel.loanMap.${element.loanType}`)} Loan Interest: ${(element.interest * 100).toFixed(1)}%`
						})
						IPFItem.push({
							Item: `${this.$t(`table.applyInfo.financialLabel.loanMap.${element.loanType}`)} Loan Prinsipal: Ksh ${element.principalAmount.toFixed(
								2
							)}`
						})
					})
				}
				exportWordDocx(
					`${basePrefix}repaymentSchedule.docx`,
					{
						Name: res.clientName,
						ID: res.nationalId,
						PhoneNumber: res.phoneNums.join('/'),
						Vehicle: res.vehicles.join('/'),
						PrincipalAmount: res.principalAmount.toFixed(2),
						Interest: (res.interest * 100).toFixed(1),
						InstallmentAmountsTable: res.installmentAmounts,
						IPFItem: IPFItem,
						Branch: res.clientName
					},
					`${res.clientName} Repayment Schedule.docx`
				)
			})
		}
	},
	created() {
		this.$nextTick(() => {
			let obj = {
				installments: 'Total',
				principalDue: 0,
				principalPaid: 0,
				principalOutstanding: 0,

				maintenanceFeeDue: 0,
				maintenanceFeeExtraPaid: 0,
				maintenanceFeeOutstanding: 0,
				maintenanceFeePaid: 0,

				trackingFeeDue: 0,
				trackingFeePaid: 0,
				trackingFeeExtraPaid: 0,
				trackingFeeOutstanding: 0,

				checkReturnFeeDue: 0,
				checkReturnFeePaid: 0,
				checkReturnFeeOutstanding: 0,

				repossessionFeeDue: 0,
				repossessionFeePaid: 0,
				repossessionFeeOutstanding: 0,

				repossessionOrderFeeDue: 0,
				repossessionOrderFeePaid: 0,
				repossessionOrderFeeOutstanding: 0,

				otherFeeDueTal: 0,
				otherFeePaidTal: 0,
				otherFeeOutstandingTal: 0,

				otherFeeDue: 0,
				otherFeePaid: 0,
				otherFeeExtraPaid: 0,
				otherFeeOutstanding: 0,

				penaltyDue: 0,
				penaltyPaid: 0,
				penaltyExtraPaid: 0,
				penaltyOutstanding: 0,

				interestDue: 0,
				interestPaid: 0,
				interestExtraPaid: 0,
				interestOutstanding: 0,

				logbookFeeDue: 0,
				logbookFeePaid: 0,
				logbookFeeOutstanding: 0,

				totalDue: 0,
				totalPaid: 0,
				totalExtraPaid: 0,
				totalOutstanding: 0
			}
			this.repayPlan.map((item) => {
				obj.principalDue += item.principalDue
				obj.principalPaid += item.principalPaid
				obj.principalOutstanding += item.principalOutstanding

				obj.maintenanceFeeDue += item.maintenanceFeeDue
				obj.maintenanceFeeExtraPaid += item.maintenanceFeeExtraPaid
				obj.maintenanceFeeOutstanding += item.maintenanceFeeOutstanding
				obj.maintenanceFeePaid += item.maintenanceFeePaid

				obj.trackingFeeDue += item.trackingFeeDue
				obj.trackingFeePaid += item.trackingFeePaid
				obj.trackingFeeExtraPaid += item.trackingFeeExtraPaid
				obj.trackingFeeOutstanding += item.trackingFeeOutstanding

				obj.checkReturnFeeDue += item.checkReturnFeeDue
				obj.checkReturnFeePaid += item.checkReturnFeePaid
				obj.checkReturnFeeOutstanding += item.checkReturnFeeOutstanding

				obj.repossessionFeeDue += item.repossessionFeeDue
				obj.repossessionFeePaid += item.repossessionFeePaid
				obj.repossessionFeeOutstanding += item.repossessionFeeOutstanding

				obj.repossessionOrderFeeDue += item.repossessionOrderFeeDue
				obj.repossessionOrderFeePaid += item.repossessionOrderFeePaid
				obj.repossessionOrderFeeOutstanding += item.repossessionOrderFeeOutstanding

				obj.otherFeeDue += item.otherFeeDue
				obj.otherFeePaid += item.otherFeePaid
				obj.otherFeeExtraPaid += item.otherFeeExtraPaid
				obj.otherFeeOutstanding += item.otherFeeOutstanding

				item.otherFeeDueTal = 0
				item.otherFeePaidTal = 0
				item.otherFeeOutstandingTal = 0
				item.totalExtraPaid = 0
				// 其他费用集合
				item.otherFeeDueTal += item.otherFeeDue + item.checkReturnFeeDue + item.repossessionFeeDue + item.repossessionOrderFeeDue + item.logbookFeeDue
				item.otherFeePaidTal +=
					item.otherFeePaid + item.checkReturnFeePaid + item.repossessionFeePaid + item.repossessionOrderFeePaid + item.logbookFeePaid
				item.otherFeeOutstandingTal +=
					item.otherFeeOutstanding +
					item.checkReturnFeeOutstanding +
					item.repossessionFeeOutstanding +
					item.repossessionOrderFeeOutstanding +
					item.logbookFeeOutstanding

				item.totalExtraPaid =
					item.trackingFeeExtraPaid + item.interestExtraPaid + item.penaltyExtraPaid + item.otherFeeExtraPaid + item.maintenanceFeeExtraPaid
				obj.otherFeeDueTal += item.otherFeeDue + item.checkReturnFeeDue + item.repossessionFeeDue + item.repossessionOrderFeeDue + item.logbookFeeDue
				obj.otherFeePaidTal +=
					item.otherFeePaid + item.checkReturnFeePaid + item.repossessionFeePaid + item.repossessionOrderFeePaid + item.logbookFeePaid
				obj.otherFeeOutstandingTal +=
					item.otherFeeOutstanding +
					item.checkReturnFeeOutstanding +
					item.repossessionFeeOutstanding +
					item.repossessionOrderFeeOutstanding +
					item.logbookFeeOutstanding

				obj.penaltyDue += item.penaltyDue
				obj.penaltyPaid += item.penaltyPaid
				obj.penaltyExtraPaid += item.penaltyExtraPaid
				obj.penaltyOutstanding += item.penaltyOutstanding

				obj.interestDue += item.interestDue
				obj.interestPaid += item.interestPaid
				obj.interestExtraPaid += item.interestExtraPaid
				obj.interestOutstanding += item.interestOutstanding

				obj.logbookFeeDue += item.logbookFeeDue
				obj.logbookFeePaid += item.logbookFeePaid
				obj.logbookFeeOutstanding += item.logbookFeeOutstanding

				obj.totalDue += item.totalDue
				obj.totalPaid += item.totalPaid
				obj.totalExtraPaid += item.totalExtraPaid
				obj.totalOutstanding += item.totalOutstanding
			})
			if (this.repayPlan.length > 0) {
				obj.duedate = this.repayPlan.at(-1).duedate
				this.repayPlanDataSource = this.repayPlan.concat([obj])
			}
			const insuranceTotalList = []
			this.insurancePlans.forEach((element) => {
				const insuranceTotal = {
					installments: 'Total',
					principalDue: element.reduce((pre, cur) => {
						return pre + cur.principalDue
					}, 0),
					principalPaid: element.reduce((pre, cur) => {
						return pre + cur.principalPaid
					}, 0),
					principalOutstanding: element.reduce((pre, cur) => {
						return pre + cur.principalOutstanding
					}, 0),
					interestDue: element.reduce((pre, cur) => {
						return pre + cur.interestDue
					}, 0),
					interestPaid: element.reduce((pre, cur) => {
						return pre + cur.interestPaid
					}, 0),
					interestOutstanding: element.reduce((pre, cur) => {
						return pre + cur.interestOutstanding
					}, 0),
					penaltyDue: element.reduce((pre, cur) => {
						return pre + cur.penaltyDue
					}, 0),
					penaltyPaid: element.reduce((pre, cur) => {
						return pre + cur.penaltyPaid
					}, 0),
					penaltyOutstanding: element.reduce((pre, cur) => {
						return pre + cur.penaltyOutstanding
					}, 0),
					totalDue: element.reduce((pre, cur) => {
						return pre + cur.totalDue
					}, 0),
					totalPaid: element.reduce((pre, cur) => {
						return pre + cur.totalPaid
					}, 0),
					totalOutstanding: element.reduce((pre, cur) => {
						return pre + cur.totalOutstanding
					}, 0)
				}
				insuranceTotal.duedate = element.at(-1).duedate
				insuranceTotalList.push(insuranceTotal)
				this.insurancePlanDataSource.push([...element, insuranceTotal])
			})
			this.emergencyPlans.forEach((element) => {
				const insuranceTotal = {
					installments: 'Total',
					principalDue: element.reduce((pre, cur) => {
						return pre + cur.principalDue
					}, 0),
					principalPaid: element.reduce((pre, cur) => {
						return pre + cur.principalPaid
					}, 0),
					principalOutstanding: element.reduce((pre, cur) => {
						return pre + cur.principalOutstanding
					}, 0),
					interestDue: element.reduce((pre, cur) => {
						return pre + cur.interestDue
					}, 0),
					interestPaid: element.reduce((pre, cur) => {
						return pre + cur.interestPaid
					}, 0),
					interestOutstanding: element.reduce((pre, cur) => {
						return pre + cur.interestOutstanding
					}, 0),
					penaltyDue: element.reduce((pre, cur) => {
						return pre + cur.penaltyDue
					}, 0),
					penaltyPaid: element.reduce((pre, cur) => {
						return pre + cur.penaltyPaid
					}, 0),
					penaltyOutstanding: element.reduce((pre, cur) => {
						return pre + cur.penaltyOutstanding
					}, 0),
					totalDue: element.reduce((pre, cur) => {
						return pre + cur.totalDue
					}, 0),
					totalPaid: element.reduce((pre, cur) => {
						return pre + cur.totalPaid
					}, 0),
					totalOutstanding: element.reduce((pre, cur) => {
						return pre + cur.totalOutstanding
					}, 0)
				}
				insuranceTotal.duedate = element.at(-1).duedate
				insuranceTotalList.push(insuranceTotal)
				this.emergencyPlanDataSource.push([...element, insuranceTotal])
			})
			const canvasDataMark = []
			this.repayPlan.forEach((item) => {
				canvasDataMark.push(item.installments)
				this.canvasDataSource.push({
					name: 'M' + item.installments + ' Receivable',
					principal: item.principalDue,
					trackingFee: item.trackingFeeDue,
					maintenanceFee: item.maintenanceFeeDue,
					otherFee: item.otherFeeDue,
					penalty: item.penaltyDue,
					interest: item.interestDue,
					total: item.totalDue
				})
				this.canvasDataSource.push({
					name: 'M' + item.installments + ' Repayment',
					principal: item.principalPaid,
					trackingFee: item.trackingFeePaid,
					maintenanceFee: item.maintenanceFeePaid,
					otherFee: item.otherFeePaid,
					penalty: item.penaltyPaid,
					interest: item.interestPaid,
					total: item.totalPaid
				})
				this.canvasDataSource.push({
					name: 'M' + item.installments + ' Balance',
					principal: item.principalOutstanding,
					trackingFee: item.trackingFeeOutstanding,
					maintenanceFee: item.maintenanceFeeOutstanding,
					otherFee: item.otherFeeOutstanding,
					penalty: item.penaltyOutstanding,
					interest: item.interestOutstanding,
					total: item.totalOutstanding
				})
			})
			this.insurancePlans.forEach((element) => {
				element.forEach((insItem) => {
					if (canvasDataMark.includes(insItem.installments)) {
						this.canvasDataSource[(insItem.installments - 1) * 3].principal += insItem.principalDue
						this.canvasDataSource[(insItem.installments - 1) * 3].penalty += insItem.penaltyDue
						this.canvasDataSource[(insItem.installments - 1) * 3].interest += insItem.interestDue
						this.canvasDataSource[(insItem.installments - 1) * 3].total += insItem.totalDue

						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].principal += insItem.principalPaid
						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].penalty += insItem.penaltyPaid
						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].interest += insItem.interestPaid
						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].total += insItem.totalPaid

						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].principal += insItem.principalOutstanding
						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].penalty += insItem.penaltyOutstanding
						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].interest += insItem.interestOutstanding
						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].total += insItem.totalOutstanding
					} else {
						this.canvasDataSource.push({
							name: 'M' + insItem.installments + ' Receivable',
							principal: insItem.principalDue,
							trackingFee: insItem.trackingFeeDue ? insItem.trackingFeeDue : 0,
							otherFee: insItem.otherFeeDue ? insItem.otherFeeDue : 0,
							maintenanceFee: insItem.maintenanceFeeDue ? insItem.maintenanceFeeDue : 0,
							penalty: insItem.penaltyDue,
							interest: insItem.interestDue,
							total: insItem.totalDue
						})
						this.canvasDataSource.push({
							name: 'M' + insItem.installments + ' Repayment',
							principal: insItem.principalPaid,
							trackingFee: 0,
							otherFee: 0,
							maintenanceFee: insItem.maintenanceFeePaid ? insItem.maintenanceFeePaid : 0,
							penalty: insItem.penaltyPaid,
							interest: insItem.interestPaid,
							total: insItem.totalPaid
						})
						this.canvasDataSource.push({
							name: 'M' + insItem.installments + ' Balance',
							principal: insItem.principalOutstanding,
							trackingFee: 0,
							otherFee: 0,
							maintenanceFee: insItem.maintenanceFeeOutstanding ? insItem.maintenanceFeeOutstanding : 0,
							penalty: insItem.penaltyOutstanding,
							interest: insItem.interestOutstanding,
							total: insItem.totalOutstanding
						})
					}
				})
			})
			this.emergencyPlans.forEach((element) => {
				element.forEach((insItem) => {
					if (canvasDataMark.includes(insItem.installments)) {
						this.canvasDataSource[(insItem.installments - 1) * 3].principal += insItem.principalDue
						this.canvasDataSource[(insItem.installments - 1) * 3].penalty += insItem.penaltyDue
						this.canvasDataSource[(insItem.installments - 1) * 3].interest += insItem.interestDue
						this.canvasDataSource[(insItem.installments - 1) * 3].total += insItem.totalDue

						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].principal += insItem.principalPaid
						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].penalty += insItem.penaltyPaid
						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].interest += insItem.interestPaid
						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].total += insItem.totalPaid

						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].principal += insItem.principalOutstanding
						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].penalty += insItem.penaltyOutstanding
						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].interest += insItem.interestOutstanding
						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].total += insItem.totalOutstanding
					} else {
						this.canvasDataSource.push({
							name: 'M' + insItem.installments + ' Receivable',
							principal: insItem.principalDue,
							trackingFee: insItem.trackingFeeDue ? insItem.trackingFeeDue : 0,
							otherFee: insItem.otherFeeDue ? insItem.otherFeeDue : 0,
							maintenanceFee: insItem.maintenanceFeeDue ? insItem.maintenanceFeeDue : 0,
							penalty: insItem.penaltyDue,
							interest: insItem.interestDue,
							total: insItem.totalDue
						})
						this.canvasDataSource.push({
							name: 'M' + insItem.installments + ' Repayment',
							principal: insItem.principalPaid,
							trackingFee: 0,
							otherFee: 0,
							maintenanceFee: insItem.maintenanceFeePaid ? insItem.maintenanceFeePaid : 0,
							penalty: insItem.penaltyPaid,
							interest: insItem.interestPaid,
							total: insItem.totalPaid
						})
						this.canvasDataSource.push({
							name: 'M' + insItem.installments + ' Balance',
							principal: insItem.principalOutstanding,
							trackingFee: 0,
							otherFee: 0,
							maintenanceFee: insItem.maintenanceFeeOutstanding ? insItem.maintenanceFeeOutstanding : 0,
							penalty: insItem.penaltyOutstanding,
							interest: insItem.interestOutstanding,
							total: insItem.totalOutstanding
						})
					}
				})
			})
			this.canvasDataSource.push({
				name: 'Total Receivable',
				principal:
					obj.principalDue +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.principalDue
					}, 0),
				trackingFee: obj.trackingFeeDue,
				maintenanceFee: obj.maintenanceFeeDue,
				otherFee: obj.otherFeeDue,
				penalty:
					obj.penaltyDue +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.penaltyDue
					}, 0),
				interest:
					obj.interestDue +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.interestDue
					}, 0),
				total:
					obj.totalDue +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.totalDue
					}, 0)
			})
			this.canvasDataSource.push({
				name: 'Total Repayment',
				principal:
					obj.principalPaid +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.principalPaid
					}, 0),
				trackingFee: obj.trackingFeePaid,
				maintenanceFee: obj.maintenanceFeePaid,
				otherFee: obj.otherFeePaid,
				penalty:
					obj.penaltyPaid +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.penaltyPaid
					}, 0),
				interest:
					obj.interestPaid +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.interestPaid
					}, 0),
				total:
					obj.totalPaid +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.totalPaid
					}, 0)
			})
			this.canvasDataSource.push({
				name: 'Total Balance',
				principal:
					obj.principalOutstanding +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.principalOutstanding
					}, 0),
				trackingFee: obj.trackingFeeOutstanding,
				maintenanceFee: obj.maintenanceFeeOutstanding,
				otherFee: obj.otherFeeOutstanding,
				penalty:
					obj.penaltyOutstanding +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.penaltyOutstanding
					}, 0),
				interest:
					obj.interestOutstanding +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.interestOutstanding
					}, 0),
				total:
					obj.totalOutstanding +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.totalOutstanding
					}, 0)
			})
		})
	}
}
</script>
<style lang="less">
.p_list {
	text-align: center;
}
.link {
	cursor: pointer;
	color: #40a9ff;
}
.border-right {
	.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
		border-right: 1px solid #e8e8e8;
	}
}
</style>
